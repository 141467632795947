<div class="content">
    <div #Col class="col-md-12" style="z-index:{{zIndex}}" *ngIf="(videos && videos.length > 0 || articles && articles.length > 0 || articlesPlus && articlesPlus.length > 0)">
        <h2 class="row__title mb-3">{{ title }}</h2>
        <div class="row__carousel" [class.displayTitle]="displayTitle">
          <fa-icon *ngIf="scrollPosition > (offset / 10)" [icon]="faChevronLeft" class="left chevron" (click)=slideLeft() ></fa-icon>
          <fa-icon *ngIf="!fillScreen && (scrollPosition <= (scrollArea - (offset / 10)))" [icon]="faChevronRight" class="right chevron" (click)=slideRight() ></fa-icon>
            <div #innerRow class="row__inner" (scroll)="onScroll($event)" (window:resize)="onResize($event)">
              <ng-container *ngFor="let video of videos; let i = index;">
                  <div #tile class="tile" *ngIf="video !== null && video !== undefined">
                      <div class="tile__media">
                        <div *ngIf="!billboard;then thumbnail else bill">here is ignored</div>
                        <ng-template #thumbnail>
                          <img class="tile__img" [src]="video.thumbnail" alt="{{'happygaytv:'+ video.title}}" />
                        </ng-template>
                        <ng-template #bill>
                            <img class="tile__img tile__img_billboard" [src]="video.billboard" alt="{{'happygaytv:'+ video.title}}" />
                        </ng-template>
                      </div>
                      <div class="tile__details" (click)="goToVideo(video)"
                          [style.margin-top.0]="(i + 1) === videos.length">
                          <div class="tile__title">
                              <p>{{(title.split(':')[0])}}: <br>{{ video.title }}</p>
                          </div>
                      </div>
                      <a [routerLink]="['/video' | localize, video.vimeoId, video.slug]"></a>
                  </div>
              </ng-container>
              <ng-container *ngFor="let article of articles; let i = index;">
                  <a [routerLink]="['/article' | localize, article.slugId, article.slug]" #tile class="tile" *ngIf="article !== null && article !== undefined">
                      <div class="tile__media">
                          <img class="tile__img" [src]="article.thumbnail" alt="{{'happygaytv:'+ article.title}}" />
                      </div>
                      <div class="tile__details"
                          [style.margin-top.0]="(i + 1) === article.length">
                          <div class="tile__title">
                              <p>{{(title.split(':')[0])}}: <br>{{ article.title }}</p>
                          </div>
                      </div>
                  </a>
              </ng-container>
              <ng-container *ngFor="let article of articlesPlus; let i = index;">
                  <a [routerLink]="['/plus' | localize, article.id]" #tile class="tile" *ngIf="article !== null && article !== undefined">
                      <div class="tile__media">
                          <img class="tile__img" [src]="article.thumbnail" alt="{{'happygaytv:'+ article.title}}" />
                      </div>
                      <div class="tile__details"
                          [style.margin-top.0]="(i + 1) === article.length">
                          <div class="tile__title">
                              <p>{{(title.split(':')[0])}}: <br>{{ article.title }}</p>
                          </div>
                      </div>
                  </a>
              </ng-container>
            </div>
        </div>
    </div>
</div>
