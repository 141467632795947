import { ErrorHandler } from '@angular/core';
import { environment } from 'environments/environment';

export class ErrorLogger implements ErrorHandler {

    static initWith(sentry: any) {
        return () => new ErrorLogger(sentry);
    }

    constructor(private sentry: any) {
        if (environment.production) {
            this.sentry.init({ dsn: 'https://962d9a5a45f040b682e88b84257d4be1@o457920.ingest.sentry.io/5454612' });
        }
    }

    handleError(error: any): void {
        if (environment.production) {
            this.sentry.captureException(error.originalError || error);
        }
        console.log(error);
        //throw error; // for default behaviour rather than silentely dying
    }
}
