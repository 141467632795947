import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { WrapperComponent } from './wrapper/wrapper.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContactDialogModule } from 'app/components/contact-dialog/contact-dialog.module';
import { PipeModule } from '@shared/pipe/pipe.module';
import { ComponentsModule } from 'app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    LocalizeRouterModule,
    PipeModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ContactDialogModule,
    ComponentsModule
  ],
  declarations: [FooterComponent, SidebarComponent, ToolbarComponent, WrapperComponent],
  exports: [FooterComponent, SidebarComponent, ToolbarComponent, WrapperComponent],
})
export class LayoutsModule {}
