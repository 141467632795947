import { Injectable } from '@angular/core';
import { Video, PlaylistVideo } from '@shared/types';
import { BehaviorSubject } from 'rxjs';
import { gql } from 'apollo-angular';
import { ApiService } from '@shared/services/api.service';


@Injectable({
  providedIn: 'root',
})

export class VideoService {

  public lastVideo = new BehaviorSubject<Video>(null);

  constructor(
    private apiService: ApiService
  ) {}


  public async canAccessVideoByVimeoId(vimeoId: string): Promise<any> {
    const result = await this.apiService.queryBackOfficeClient<{
      canAccessVideoByVimeoId: any;
    }>(
      gql`
        query canAccessVideoByVimeoId {
          canAccessVideoByVimeoId(vimeoId: "${vimeoId}") {
            canAccessVideo
            level
          }
        }
      `,
    );
    return result ? result.canAccessVideoByVimeoId : null;
  }

  public async canAccessVideo(videoId: string): Promise<boolean> {
    const result = await this.apiService.queryBackOfficeClient<{
      canAccessVideo: boolean;
    }>(
      gql`
        query {
          canAccessVideo(videoId: "${videoId}")
        }
      `,
    );
    return result.canAccessVideo;
  }

  public async getVideoByVimeoId(vimeoId: string, adminToken?: boolean): Promise<Video> {
    const result = await this.apiService.queryBackOfficeClient<{
      getVideoByVimeoId: Video;
    }>(
      gql`
        query getVideoByVimeoId {
          getVideoByVimeoId(vimeoId: "${vimeoId}") {
            id
            title
            metaDescription
            browserTitle
            vimeoId
            description
            subCategoryId
            categoryId
            trailerId
            thumbnail
            publishDate
            slug
            billboard
            cover
            coverAlt
            club
            plus
            clubLevel
            plusLevel
          }
        }
      `, null, null, adminToken
    );
    this.lastVideo.next(result.getVideoByVimeoId);
    return result.getVideoByVimeoId;
  }

  public async getVideoById(id: string): Promise<Video> {
    const result = await this.apiService.queryBackOfficeClient<{
      getVideoById: Video;
    }>(
      gql`
        query getVideoById {
          getVideoById(id: "${id}") {
            id
            title
            vimeoId
            description
            subCategoryId
            categoryId
            trailerId
            thumbnail
            metaDescription
            browserTitle
            slug
            club
            plus
            clubLevel
            plusLevel
          }
        }
      `,
    );
    this.lastVideo.next(result.getVideoById);
    return result.getVideoById;
  }

  public async getVideosByCategory(
      id?: number
    ): Promise<{
      videos: Video[];
      categoryId: number;
      categoryTitle: string;
      position: number;
      club: boolean;
      plus: boolean;
    }[]> {
    const result = await this.apiService.queryBackOfficeClient<{
      getVideosByCategory: {
        videos: Video[];
        categoryId: number;
        categoryTitle: string;
        position: number;
        club: boolean;
        plus: boolean;
      }[];
    }>(
      gql`
        query getVideosByCategory {
          getVideosByCategory(categoryId: ${id ? id : null}) {
            videos {
              id
              title
              vimeoId
              description
              subCategoryId
              trailerId
              thumbnail
              publishDate
              metaDescription
              browserTitle
              billboard
              slug
              cover
              coverAlt
              club
              plus
              clubLevel
              plusLevel
            }
            categoryTitle
            categoryId
            position
            plus
            club
            plusLevel
            clubLevel
          }
        }
      `,
    );
    return result.getVideosByCategory;
  }

  public async getVideosBySubCategory(
    id?: number
  ): Promise<
    {
      videos: Video[];
      categoryId: number;
      categoryTitle: string;
      position: number;
      club: boolean;
      plus: boolean;
    }[]
  > {
  const result = await this.apiService.queryBackOfficeClient<{
      getVideosBySubCategory: {
        videos: Video[];
        categoryId: number;
        categoryTitle: string;
        position: number;
        club: boolean;
        plus: boolean;
      }[];
    }>(
      gql`
        query getVideosBySubCategory {
          getVideosBySubCategory(subCategoryId: ${id ? id : null}) {
            videos {
              id
              title
              vimeoId
              description
              subCategoryId
              trailerId
              thumbnail
              publishDate
              metaDescription
              browserTitle
              billboard
              slug
              cover
              coverAlt
              club
              plus
            }
            categoryTitle
            categoryId
            position
            club
            plus
            plusLevel
            clubLevel
          }
        }
      `,
    );
    return result.getVideosBySubCategory;
  }

  public async queryPlaylistVideoByType(playlistType: string): Promise<PlaylistVideo[]> {
    const result = await this.apiService.queryBackOfficeClient(
      gql`
        query getPlaylistByType($playlistType: String) {
          getPlaylistByType(type: $playlistType) {
            playlistId,
            type,
            title,
            id,
            browserTitle,
            metaDescription,
            vimeoId,
            trailerId,
            description,
            subCategoryId,
            categoryId,
            thumbnail,
            createdDate,
            publishDate,
            slug
            cover
            coverAlt
            club
            plus
            plusLevel
            clubLevel
          }
        }
      `, { playlistType }
    );
    if (result) {
      return result.getPlaylistByType;
    }
    return null;
  }
}
