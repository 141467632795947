import { Component, Input, OnInit, AfterViewInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatesService } from '@shared/translates';
import { ApiService } from '@shared/services/api.service';
import { Video, Tag } from '@shared/types';
import Player from '@vimeo/player';
import { VideoDialogComponent } from '../video-dialog/video-dialog.component';
import { VideoSliderService } from '../video-slider/video-slider.service';
import { VideoService } from '../../video/video.service';
import { LoginDialogComponent } from 'app/auth/components/login-dialog/login-dialog.component';
import { RegistrationDialogComponent } from 'app/auth/components/registration-dialog/registration-dialog.component';
import { LostPasswordDialogComponent } from 'app/auth/components/lost-password-dialog/lost-password-dialog.component';
import { AuthService } from '@shared/services/auth.service';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';

enum VolumeIcon {
  ON = '/assets/img/volume_on.svg',
  OFF = '/assets/img/mute.svg',
}

@Component({
  selector: 'app-video-caroussel',
  templateUrl: './video-caroussel.component.html',
  styleUrls: ['./video-caroussel.component.scss'],
})
export class VideoCarousselComponent implements OnInit, AfterViewInit {
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faPlayCircle = faPlayCircle;
  faPauseCircle = faPauseCircle;

  private isVolumeOn: boolean;
  public volumeIcon = VolumeIcon.OFF;

  @Input('config') slideConfig: any;

  @Input('trailers') trailers: Video[];
  @Input('connection') connection = false;

  public frames: Array<Video> = new Array<Video>();
  private players: Array<Player> = new Array<Player>();

  public frameLenght: number;
  public currentFramePosition: number;
  public trailerPosition = 0;

  loopstarted = false;
  played = false;

  playing = false;

  public videoModal = false;
  forcedPause = false;
  moveOperation = false;
  infiniteTreshold = 3;

  addBack = false;
  addFront = false;

  isSafari;
  isiOS;
  hasMultiTouch;
  constructor(
    private sanitizer: DomSanitizer,
    private readonly _translatesService: TranslatesService,
    private readonly videoSliderService: VideoSliderService,
    private readonly apiService: ApiService,
    private modalService: NgbModal,
    private readonly videosliderService: VideoSliderService,
    private domSanitizer: DomSanitizer,
    private readonly videoService: VideoService,
    private readonly authService: AuthService,
    private route: Router,
  ) {}

  ngOnInit(): void {
    this.trailers = this.trailers.filter((trailer) => Number(trailer.trailerId) > 0);
    this.trailers.sort(
      (a, b) =>
      Number(a.publishDate.toString()) - Number(b.publishDate.toString()),
    );
    this.isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    this.isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    this.hasMultiTouch = navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
  }

  ngAfterViewInit() {
    this.frameLenght = this.trailers.length > this.infiniteTreshold ? this.infiniteTreshold : this.trailers.length;
    this.currentFramePosition = this.trailers.length > this.infiniteTreshold ? 1 : 0;

    if (this.trailers.length > this.infiniteTreshold) {
      this.setFrames(-1);
    } else {
      this.setFrames(0);
    }
  }

  public setFrames(offset: number) {
    if (offset === -1) {
      const frame = this.trailers[this.trailers.length - 1];
      frame.vimeoId = this.toVimeoUrl(frame.trailerId);
      this.frames.push(frame);
    }
    this.trailers.forEach((trailer, index) => {
      if (this.trailers.length > this.infiniteTreshold) {
        if (this.frames.length < this.frameLenght && index >= offset) {
          trailer.vimeoId = this.toVimeoUrl(trailer.trailerId);
          this.frames.push(trailer);
        }
      } else if (index >= offset) {
        trailer.vimeoId = this.toVimeoUrl(trailer.trailerId);
        this.frames.push(trailer);
      }
    });
  }


  hideFrame(index: number) {
    if (this.trailers.length > this.infiniteTreshold) {
      if (index !== 1) {
        return true;
      }
      return false;
    } else {
      return index === this.currentFramePosition ? false : true;
    }
  }

  toVimeoUrl(vimeoId: string) {
    return this.sanitizer.sanitize(SecurityContext.URL, 'https://player.vimeo.com/video/' + vimeoId + '?controls=0&muted=1&quality=720');
  }

  public async iframeLoaded(frame: HTMLIFrameElement, videoFrame: Video, framePosition: number) {
    if (framePosition !== null && framePosition !== -1) {
      const player = new Player(frame);
      if (framePosition === 0 && this.players.length === this.frameLenght - 1 && this.loopstarted) {
        this.players.unshift(player);
      } else {
        this.players[framePosition] = player;
      }
      if (this.loopstarted || this.players.length === this.frameLenght) {
        await this.startPlayback();
        this.loopstarted = true;
      }
    }
  }

  public addFrame(front: boolean, video: Video) {
    if (front) {
      this.frames.shift();
      this.players.shift();
      video.vimeoId = this.toVimeoUrl(video.trailerId);
      this.frames.push(video);
      this.addFront = true;
      return;
    } else {
      this.frames.pop();
      this.players.pop();
      video.vimeoId = this.toVimeoUrl(video.trailerId);
      this.frames.unshift(video);
      this.addBack = true;
      return;
    }
  }

  public async startPlayback() {
    if (this.players[this.currentFramePosition]) {
      const start = new Date().getTime();
      const overlay = document.getElementsByClassName('overlay');
      for (let i = 0; i < overlay.length; i++) {
        overlay[i].classList.add('transparent');
      }

      await this.players[this.currentFramePosition].ready();

      if (await this.players[this.currentFramePosition].getCurrentTime() > 0) {
        await this.players[this.currentFramePosition].setCurrentTime(0);
      }

      if (this.trailers.length === 1) {
        this.players[this.currentFramePosition].setLoop(true);
      }

      if (this.isSafari && (this.isiOS || this.hasMultiTouch)) {
        this.isVolumeOn = false;
        this.volumeIcon = VolumeIcon.OFF;
        this.players[this.currentFramePosition].setMuted(true);
      } else {
        if (this.isVolumeOn) {
          this.players[this.currentFramePosition].setMuted(false);
        }
      }
      const end = new Date().getTime();
      const time = end - start;

      if (time < 1500) {
        setTimeout(() => {
          this.play();
        }, 1500 - time);
      } else {
        this.play();
      }

      this.players[this.currentFramePosition].on('error', async(e) => {
        if (e.method === 'NotAllowedError') {
        }
        // console.log(e);
      });
      this.players[this.currentFramePosition].on('play', () => {
        this.playing = true;
        if (!this.played) {
          this.players[this.currentFramePosition].on('ended', async () => {
            if (this.played) {
              await this.next();
            }
          });
        }
        this.played = true;
      });
    } else {
      // console.log('unable to start playback');
    }
  }

  public async pausePlayBack(forcedPause?: boolean) {
    if (this.players[this.currentFramePosition] && this.played) {
      await this.players[this.currentFramePosition].pause();
      this.playing = false;
      this.played = false;
      if (forcedPause) {
        this.forcedPause = true;
      }
    } else {
      // console.log('unable to pause');
    }
  }

  public async play(forcedPlay?: boolean) {
    if (!this.videoModal && this.players[this.currentFramePosition] && !this.played && !this.moveOperation) {
      try {
        if (this.forcedPause && forcedPlay) {
          await this.players[this.currentFramePosition].play();
          this.forcedPause = false;
        } else if (!this.forcedPause) {
          await this.players[this.currentFramePosition].play();
        }
      } catch (err) {
        // console.log(err);
      }
    } else {
      // console.log('unable to play');
    }
  }

  async next() {
    if (this.players[this.currentFramePosition]) {
      this.playing = false;

      this.played = false;
      this.moveOperation = true;

      this.players[this.currentFramePosition].off('ended');
      this.players[this.currentFramePosition].off('play');
      this.players[this.currentFramePosition].off('error');
      await this.players[this.currentFramePosition].pause();


      if (this.trailers.length <= this.infiniteTreshold) {
        const prevFramePosition = this.currentFramePosition;
        this.currentFramePosition++;
        if (this.currentFramePosition >= this.trailers.length) {
          this.currentFramePosition = 0;
        }
        await this.startPlayback();
        setTimeout(async () =>  {
          await this.players[prevFramePosition].setCurrentTime(0);
          await this.players[prevFramePosition].unload();
        }, 2000);
      } else {
        if (this.trailerPosition + 1 === this.trailers.length - 1) {
          this.trailerPosition = -1;
        } else if (this.trailerPosition === this.trailers.length - 1) {
          this.trailerPosition = 0;
        } else {
          this.trailerPosition++;
        }
        if (this.trailerPosition >= this.trailers.length) {
          this.trailerPosition = 1;
        }

        this.addFrame(true, this.trailers[this.trailerPosition + 1]);
        setTimeout(async () =>  {
          await this.players[this.currentFramePosition - 1].setCurrentTime(0);
          await this.players[this.currentFramePosition - 1].unload();
        }, 2000);
      }
      this.moveOperation = false;
    }
  }


  async prev() {
    if (this.players[this.currentFramePosition]) {
      this.playing = false;

      this.moveOperation = true;
      this.played = false;
      this.players[this.currentFramePosition].off('ended');
      this.players[this.currentFramePosition].off('play');
      this.players[this.currentFramePosition].off('error');
      await this.players[this.currentFramePosition].pause();

      if (this.trailers.length <= this.infiniteTreshold) {
        const prevFramePosition = this.currentFramePosition;
        this.currentFramePosition--;
        if (this.currentFramePosition < 0) {
          this.currentFramePosition = this.trailers.length - 1;
        }
        await this.startPlayback();
        await this.players[prevFramePosition].unload();
      } else {
        if (this.trailerPosition === 0) {
          this.trailerPosition = this.trailers.length - 1;
        } else if (this.trailerPosition - 1 === 0) {
          this.trailerPosition = this.trailers.length;
        } else {
          this.trailerPosition--;
        }
        if (this.trailerPosition <= 0) {
          this.trailerPosition = this.trailers.length - 2;
        }
        this.addFrame(false, this.trailers[this.trailerPosition - 1]);
        await this.players[this.currentFramePosition].unload();
      }
      this.moveOperation = false;
    }
  }

  public mute() {
    if (this.isVolumeOn) {
      this.volumeIcon = VolumeIcon.OFF;
      if (this.players[this.currentFramePosition]) {
        this.players[this.currentFramePosition].setMuted(true);
      }
      this.isVolumeOn = false;
    } else {
      this.volumeIcon = VolumeIcon.ON;
      if (this.players[this.currentFramePosition]) {
        this.players[this.currentFramePosition].setMuted(false);
      }
      this.isVolumeOn = true;
    }
  }

  public async goToVideo(frame: Video) {
    if (!frame.plus) {
      if (this.connection) {
        await this.login();
      } else if (this.played && !this.moveOperation) {
        if (frame.club) {
          const canAccessVideo = await this.videoService.canAccessVideo(frame.id);
          if (!canAccessVideo) {
            this.route.navigate(['/' + this._translatesService.getCurrentLang() + '/classvip']);
            return;
          }
        }
        const video = await this.videoService.getVideoById(frame.id);

        if (video.browserTitle === null) {
          return;
        }
        this.videoModal = true;
        await this.players[this.currentFramePosition].pause();
        const modalRef = this.modalService.open(VideoDialogComponent, {
          size: 'lg',
          scrollable: true,
          windowClass: 'video-modal'
        });
        modalRef.componentInstance.video = video;
        modalRef.result.catch(async () => {
          this.next();
          this.videosliderService.closeModal();
          this.videoModal = false;
          const overlay = document.getElementsByClassName('overlay');

          for (let i = 0; i < overlay.length; i++) {
            overlay[i].classList.remove('transparent');
          }
        });
      }
    }
  }

  async login() {
    this.pausePlayBack(true);
    let clubConnect = false;
    const auth = this.authService.isAuthenticated();
    if (auth && this.authService.getUserType() !== 'club') {
      clubConnect = true;
    }
    const res = await this.startModal('login', clubConnect);
    if (res === 'success') {
      if (auth) {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        window.location.reload();
      }
    } else if (res === 'registration') {
      let resReg = res;
      while (resReg !== 'close') {
        resReg = await this.startModal(resReg);
        if (resReg === 'success') {}
        if (resReg === 'success' || resReg === 0) {
          setTimeout(() => {
            window.location.reload();
          }, 3500);
          return;
        }
      }
    } else if (res === 'lost-password') {
      const resLost = await this.startModal('lost-password');
    }
    this.play(true);
  }

  async startModal(modalName: string, clubConnect?: boolean) {
    try {
      if (modalName === 'login') {
        const modal = this.modalService.open(LoginDialogComponent, {
          windowClass: 'login modal',
          scrollable: true,
        });
        modal.componentInstance.origin = Tag.CLUB;
        modal.componentInstance.clubConnect = clubConnect;
        return await modal.result;
      } else if (modalName === 'registration') {
        const modal = this.modalService.open(RegistrationDialogComponent, {
          windowClass: 'registration modal',
          scrollable: true,
        });
        modal.componentInstance.origin = Tag.CLUB;
        return await modal.result;
      } else if (modalName === 'lost-password') {
        const modal = this.modalService.open(LostPasswordDialogComponent, {
          windowClass: 'lost-password modal',
          scrollable: true,
        });
        return await modal.result;
      }
      return '';
    } catch (e) {
      return 0;
    }
  }
}
