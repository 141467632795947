import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ContactDialogComponent } from 'app/components/contact-dialog/contact-dialog.component';
import { BlogService } from 'app/blog/blog.service';
import { VideoService } from 'app/video/video.service';
import { TranslatesService } from '@shared/translates';

@Injectable()
export class TinyReplacerService {
  domain: string;
  lang: string;

  constructor(
    private modalService: NgbModal,
    private blogService: BlogService,
    private videoService: VideoService,
    private translatesService: TranslatesService,
  ) {}

  public async replaceTinyTxt(text: string): Promise<string> {
    const url = window.location.href.split('/');
    this.domain = url[0] + '//' + url[1] + '/' + url[2] + '/' + url[3];
    this.lang = this.translatesService.getCurrentLang();

    const regex = new RegExp(/@\S*_\(.*?\)\W/, 'g');

    let match;
    const output = [];
    while ((match = regex.exec(text)) !== null) {
      output.push(match);
    }

    for (const out of output) {
      const extract = out[0].split('_');

      const method = extract[0].substring(1);
      if ((out[0][out[0].length - 1] === '<' || out[0][out[0].length - 1] === '&') && out[0][out[0].length - 2] === ')') {
        out[0] = out[0].slice(0, -1);
      }
      if (method === 'contact') {
        text = this.handleContact(extract, text, out);
      } else if (method === 'article') {
        text = await this.handleArticle(extract, text, out);
      } else if (method === 'video') {
        text = await this.handleVideo(extract, text, out);
      } else if (method === 'card') {
        text = this.handleCard(extract, text, out);
      } else if (method === 'login') {
        text = this.handleLogin(extract, text, out);
      } else if (method === 'gpa') {
        text = this.handleLoginGPA(extract, text, out);
      } else if (method === 'cookie') {
        text = this.handleCookie(extract, text, out);
      }
    }
    return text;
  }

  handleContact(extract: string, text: string, output: any) {
    const value = extract[1].match(/\(([^)]+)\)/)[1];

    text = text.replace(output[0],
      '<a class="generatedContact" style="text-decoration: underline;cursor: pointer">' + value + ' ' + '</a>');
    return text;
  }

  handleCard(extract: string, text: string, output: any) {
    const value = extract[1].match(/\(([^)]+)\)/)[1];

    text = text.replace(output[0],
      '<a class="generatedCard" onclick="removeCard()"; style="text-decoration: underline;cursor: pointer">' + value + ' ' + '</a>');
    return text;
  }

  handleLogin(extract: string, text: string, output: any) {
    const value = extract[1].match(/\(([^)]+)\)/)[1];

    text = text.replace(output[0],
      '<a class="generatedCard" onclick="connect()"; style="text-decoration: underline;cursor: pointer">' + value + ' ' + '</a>');
    return text;
  }

  handleLoginGPA(extract: string, text: string, output: any) {
    const value = extract[1].match(/\(([^)]+)\)/)[1];

    text = text.replace(output[0],
      '<a class="generatedCard" onclick="connectGPA()"; style="text-decoration: underline;cursor: pointer">' + value + ' ' + '</a>');
    return text;
  }

  handleCookie(extract: string, text: string, output: any) {
    const value = extract[1].match(/\(([^)]+)\)/)[1];

    text = text.replace(output[0],
      '<a class="generatedCookie" onclick="extraCookie();" style="text-decoration: underline;cursor: pointer">' + value + ' ' + '</a>');
    return text;
  }

  async handleArticle(extract: string, text: string, output: any) {
    const value = extract[1].match(/\(([^)]+)\)/)[1];
    const id = extract[2].match(/\(([^)]+)\)/)[1];
    const article = await this.blogService.getArticleBySlugId(id);

    text = text.replace(output[0], '<a id="generatedArt' + output.index + ' "href="' + this.domain + '/article/'
     + article.slugId + '/' + article.slug + '">' + value + ' ' + '</a>');

    return text;
  }

  async handleVideo(extract: string, text: string, output: any) {
    const value = extract[1].match(/\(([^)]+)\)/)[1];

    const id = extract[2].match(/\(([^)]+)\)/)[1];
    const video = await this.videoService.getVideoByVimeoId(id);

    text = text.replace(output[0], '<a id="generatedVid' + output.index + ' "href="' + this.domain + '/video/' +
    video.vimeoId + '/' + video.slug + '">' + value + ' ' + '</a>');

    return text;
  }

  async setContactListener() {
    await new Promise(f => setTimeout(f, 1000));

    const contact = document.getElementsByClassName('generatedContact');
    Array.from(contact).forEach((item) => {
      item.addEventListener('click', async e => {
        const modal = this.modalService.open(ContactDialogComponent, {
          windowClass: 'contact modal',
          scrollable: true,
        });
        const ret = await modal.result;
      });
    });
  }
}
