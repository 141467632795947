import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class VideoSliderService {
  private _navItemSource = new BehaviorSubject<boolean>(false);
  constructor() {}

  navItem$ = this._navItemSource.asObservable();
  // service command
  closeModal() {
    this._navItemSource.next(true);
  }
}
