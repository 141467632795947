<div class="modal-header" ngbAutofocus>
  <div class="share">
    <fa-icon [icon]="faShare"></fa-icon>
    {{'data.share' | translate}}
  </div>
  <app-button class="timesCircle" aria-label="Close" (click)="activeModal.dismiss('Cross click')" data-dismiss="modal" [isLink]="true" [centerButton]="faTimesCircle" [color]='colorExitModal'></app-button>
</div>
<div class="modal-cont" style="border: none;">
  <div class="modal-body" style="text-align: center;">
    <div class="col-md">
      <fa-stack data-toggle="tooltip" data-placement="bottom" title="Facebook">
        <a href="https://www.facebook.com/sharer/sharer.php?u={{url}}" target="_blank">
          <fa-icon [icon]="faCircle" stackItemSize="2x" class="circle"></fa-icon>
          <fa-icon [icon]="faFacebookF" class="icon" stackItemSize="1x"></fa-icon>
        </a>
      </fa-stack>
      <fa-stack data-toggle="tooltip" data-placement="bottom" title="Twitter">
        <a href="https://twitter.com/intent/tweet?text={{browserTitle}};url={{url}};via=happygaytv.com" target="_blank">
          <fa-icon [icon]="faCircle" stackItemSize="2x" class="circle"></fa-icon>
          <fa-icon [icon]="faTwitter" class="icon" stackItemSize="1x" ></fa-icon>
        </a>
      </fa-stack>
      <fa-stack data-toggle="tooltip" data-placement="bottom" title="Whatsapp">
        <a href="https://api.whatsapp.com/send?text={{url}}" target="_blank">
          <fa-icon [icon]="faCircle" stackItemSize="2x" class="circle"></fa-icon>
          <fa-icon [icon]="faWhatsapp" class="icon" stackItemSize="1x" ></fa-icon>
        </a>
      </fa-stack>

      <fa-stack data-toggle="tooltip" data-placement="bottom" title="Tumblr">
          <a href="https://www.tumblr.com/widgets/share/tool?canonicalUrl={{url}}" target="_blank">
          <fa-icon [icon]="faCircle" stackItemSize="2x" class="circle"></fa-icon>
          <fa-icon [icon]="faTumblr" class="icon" stackItemSize="1x" ></fa-icon>
        </a>
      </fa-stack>
      <fa-stack data-toggle="tooltip" data-placement="bottom" title="{{ 'data.tooltip-link' | translate }}">
        <a (click)="copyUrl()" style="cursor:pointer">
          <fa-icon [icon]="faCircle" stackItemSize="2x" class="circle"></fa-icon>
          <fa-icon id="link" [icon]="faLink" class="icon" stackItemSize="1x" ></fa-icon>
          <fa-icon id="check" style="display:none" [icon]="faCheck" class="icon" stackItemSize="1x" ></fa-icon>
        </a>
      </fa-stack>
      <fa-stack data-toggle="tooltip" data-placement="bottom" title="{{ 'data.tooltip-mail' | translate }}">
        <a href="mailto:?subject={{subject}}&body={{body}}" target="_blank">
          <fa-icon [icon]="faCircle" stackItemSize="2x" class="circle"></fa-icon>
          <fa-icon [icon]="faEnvelope" class="icon" stackItemSize="1x" ></fa-icon>
        </a>
      </fa-stack>
    </div>
  </div>
</div>
