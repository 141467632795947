import { Component } from '@angular/core';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { ContactDialogComponent } from 'app/components/contact-dialog/contact-dialog.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],

})
export class FooterComponent {
    faCircle = faCircle;
    faInstagram = faInstagram;
    faTwitter = faTwitter;
    faFacebookF = faFacebookF;
    currentYear: number = new Date().getFullYear();

    constructor(
      private modalService: NgbModal
    ) {}

    async contactDialog() {
      const modal = this.modalService.open(ContactDialogComponent, {
        windowClass: 'contact modal',
        scrollable: true,
      });
      const ret = await modal.result;
    }
}
