<div class="header-contact">
  <app-button class="timesCircle" aria-label="Close" (click)="activeModal.close('close')" data-dismiss="modal" [isLink]="true" [centerButton]="faTimesCircle" [color]='colorExitModal'></app-button>
  <img class="logo" src="/assets/img/logo.png" alt="footer logo">
</div>
<div class="modal-body">
  <div class='content'>
    <div class="items">
      <div (click)="hideEssential = !hideEssential">
        <fa-icon *ngIf="hideEssential" [icon]="faChevronDown"></fa-icon>
        <fa-icon *ngIf="!hideEssential" [icon]="faChevronUp"></fa-icon>
        {{ 'data.cookie.essential' | translate }}
      </div>
      <div>{{ 'data.cookie.required' | translate }}</div>
    </div>
    <div class="txt" (click)="hideEssential = !hideEssential" [ngClass]="{'hidden': hideEssential}">
      {{ 'data.cookie.txt' | translate }}</div>
    <div class="items">
      <div (click)="hideMarketing = !hideMarketing">
        <fa-icon *ngIf="hideMarketing" [icon]="faChevronDown"></fa-icon>
        <fa-icon *ngIf="!hideMarketing" [icon]="faChevronUp"></fa-icon>
        {{ 'data.cookie.marketing' | translate }}
      </div>
      <div class="buttons">
        <app-button [title]="'data.cookie.deny' | translate" [isLink]=true [border]=true [color]="!acceptMarketing ? darkBlue : transparent" (click)="!acceptMarketing ? null: marketing()"></app-button>
        <app-button [title]="'data.cookie.allow' | translate" [isLink]=true [border]=true [color]="acceptMarketing ? null : transparent" (click)="acceptMarketing ? null: marketing()"></app-button>
      </div>
    </div>
    <div class="txt" (click)="hideMarketing = !hideMarketing" [ngClass]="{'hidden': hideMarketing}">{{ 'data.cookie.txt-marketing' | translate }}</div>
    <div class='cookie-ext'>
      <a href='{{currentLang}}/pages/cookies' target="_blank">{{ 'data.cookie.ext' | translate }}</a>
    </div>
  </div>
  <div class="valid">
    <app-button [title]="'data.cookie.deny' | translate" [isLink]=true [border]=true [color]="darkBlue" (click)="onSubmit(false)"></app-button>
    <app-button [title]="'data.cookie.allow' | translate" [isLink]=true [border]=true (click)="onSubmit(true)"></app-button>
  </div>
</div>
