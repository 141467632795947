import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorsDirective } from '@shared/directive/validators.directive';
import { RegistrationDialogService } from './registration-dialog.service';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '@shared/services/auth.service';
import { Tag } from '../../../shared/types';
import { UserService } from '@shared/services/user.service';
import { TranslatesService } from '@shared/translates';
import { StateService } from '@shared/services/state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-dialog',
  templateUrl: './registration-dialog.component.html',
  styleUrls: ['./registration-dialog.component.scss'],
})
export class RegistrationDialogComponent implements OnInit {
  form: FormGroup;
  isSubmitted: boolean;
  color = 'pink';
  faTimesCircle = faTimesCircle;
  colorExitModal = 'transparent';
  origin: Tag;
  error = false;

  currentLang;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public activeModal: NgbActiveModal,
    private _translatesService: TranslatesService,
    public userService: UserService,
    private stateService: StateService,
    public route: Router,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      nickname: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordValidation: ['', [Validators.required, Validators.minLength(6)]],
      cgv: ['', Validators.requiredTrue],
      text: [''],
    });
    this.f.password.valueChanges.subscribe(value => {
      this.form.controls.passwordValidation.setValidators([
        Validators.required,
        Validators.minLength(6),
        ValidatorsDirective.checkSamePassword(value),
      ]);
    });
    this.currentLang = this._translatesService.getCurrentLang();
  }

  get f() {
    return this.form.controls;
  }

  async onSubmit() {
    this.isSubmitted = true;

    if (this.form.invalid) {
      return;
    }
    if (this.f.text.value) {
      return;
    }
    try {
      this.stateService.loading.next(true);

      const ret = await this.authService.register({
        nickname: this.f.nickname.value,
        password: this.f.password.value,
        email: this.f.email.value,
        cgv: this.f.cgv.value,
        origin: this.origin,
      });
      if (!ret.token) {
        this.activeModal.close(0);
        return;
      }
      if (this.origin === Tag.CLUB) {
        await this.authService.refreshToken('Bearer ' + this.authService.token);
        this.route.navigate(['/' + this._translatesService.getCurrentLang() + '/thanksclassvip']);
      }

    } catch (e) {
      this.error = true;
      return;
    } finally {
      this.stateService.loading.next(false);
    }
    this.activeModal.close('success');
  }

  backToLogin() {
    this.activeModal.close('login');
  }
}
