import { AfterViewInit, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TranslatesService } from '@shared/translates';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Video, PlaylistVideo, PlaylistType  } from '@shared/types';
import { SharedModalComponent } from 'app/components/shared-modal/shared-modal.component';
import Player from '@vimeo/player';
import { cloneDeep } from 'lodash';
import { VideoDialogService } from './video-dialog.service';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { TinyReplacerService } from '@shared/services/tinyReplacer.service';
import { VideoService } from '../../video/video.service';
import { AuthService } from '@shared/services/auth.service';
import { ExtFormService } from '@shared/services/extForm.service';
import { TipModalComponent } from 'app/components/tip-modal/tip-modal.component';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
})
export class VideoDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() video: Video;
  public src: SafeUrl;
  public currentVideo: Video;
  private player: Player;
  public bool = false;
  public categorizedVideo: {
    videos: Video[];
  };
  public body;

  public orgPlaylist: PlaylistVideo[];
  public playlist: PlaylistVideo[];

  faTimesCircle = faTimesCircle;
  colorExitModal = 'transparent';
  level: number;

  public isClub3 = false;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly videoService: VideoService,
    private readonly _translatesService: TranslatesService,
    private readonly videoDialogService: VideoDialogService,
    private modalService: NgbModal,
    private tinyReplacerService: TinyReplacerService,
    private authService: AuthService,
    private extFormService: ExtFormService,
  ) {}

  async ngAfterViewInit() {
    const currentIframe = document.getElementById('iframe');
    this.player = new Player(currentIframe);
    this.player.enableTextTrack(this._translatesService.getCurrentLang()).catch(e => {
      console.log(e);
    });
    this.player.on('loaded', () => {
    this.body = document.getElementById('modal-body').addEventListener('scroll', () => {
      const height = $('#modal-body').scrollTop();
      const iframe = document.getElementById('iframe');

      if (iframe.getBoundingClientRect().height - (height / 3) < height) {
        this.player.pause();
      } else {
        this.player.play();
      }
    });
      this.player.play().catch(e => {
        console.error(e);
      });
    });
    this.currentVideo.description = await this.tinyReplacerService.replaceTinyTxt(this.currentVideo.description);
  }

  async ngOnInit(): Promise<void> {
    const modalBody = document.getElementById('modal-body');
    this.currentVideo = this.video;
    modalBody.scrollTop = 0;
    this.src = `https://player.vimeo.com/video/${this.currentVideo.vimeoId}?controls=1&muted=0&quality=720p`;


    const tempVid = await this.videoService.getVideosByCategory(this.currentVideo.categoryId);
    const filteredtempVid = tempVid[0];

    filteredtempVid.videos = filteredtempVid.videos.filter((video) => video.id !== this.currentVideo.id);
    filteredtempVid.videos = this.shuffle(filteredtempVid.videos);

    this.categorizedVideo = cloneDeep(
      filteredtempVid
    );
    modalBody.scrollTop = 0;

    this.bool = true;

    this.videoDialogService.navItem$.subscribe(async item => {
      if (item !== null) {
        this.video = item;
        this.currentVideo = this.video;
        this.src = `https://player.vimeo.com/video/${this.currentVideo.vimeoId}?controls=1&muted=0&quality=720p`;
      }

      if (this.currentVideo.club) {
        this.orgPlaylist = await this.videoService.queryPlaylistVideoByType(PlaylistType.CLUB);
      } else if (this.currentVideo.plus) {
        this.orgPlaylist = await this.videoService.queryPlaylistVideoByType(PlaylistType.PLUS);
      } else {
        this.orgPlaylist = await this.videoService.queryPlaylistVideoByType(PlaylistType.GLOBAL);
      }

      const level = this.authService.getUserLevel();
      const type = this.authService.getUserType();

      if (this.orgPlaylist) {
        this.orgPlaylist = this.orgPlaylist.filter((video) => {
          if (!video.club && !video.plus) {
            return video;
          } else if ((video.club && type === 'club' && level >= video.clubLevel) ||
           (video.plus && type === 'plus' && level < video.plusLevel)) {
            return video;
          }
          return null;
        });
        this.playlist = [...this.orgPlaylist];

        const position = this.playlist.findIndex((video) => video.vimeoId === this.currentVideo.vimeoId);

        if (this.playlist.length > 0) {
          this.playlist = this.playlist.slice(position + 1, this.playlist.length);
        }
      }

      modalBody.scrollTop = 0;
      await this.startPlayback();
    });
  }

  ngOnDestroy() {
    if (this.player !== null && this.player !== undefined) {
      this.player.destroy();
      this.player = null;
    }
    if (this.body) {
      this.body.removeEventListener('scroll');
    }
  }

  shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  async startPlayback() {
    const currentIframe = document.getElementById('iframe');
    this.player = new Player(currentIframe);

    await this.player.ready();

    setTimeout(async () => {
      this.player
      .enableTextTrack(this._translatesService.getCurrentLang())
      .catch(e => {
        console.log(e);
      });

      this.player.on('ended', async() => { // player bug: need to wait before attach event
        this.player.off('ended');

        if (this.playlist) {
          if (this.playlist.length === 0) {
            this.playlist = [...this.orgPlaylist];
          }

          this.currentVideo = this.playlist[0];
          this.playlist.shift();

          this.playlist = [...this.playlist];
        }

        this.player.loadVideo(Number(this.currentVideo.vimeoId));
        this.currentVideo.description = await this.tinyReplacerService.replaceTinyTxt(this.currentVideo.description);
        this.player.unload();
        this.player.loadVideo(Number(this.currentVideo.vimeoId));

        await this.startPlayback();
      });
      await this.player.play().catch(e => {
        //console.error(e);
      });
    }, 1000);
  }

  public async showShareModal(video: Video) {
    this.player.pause();
    const modalRef = this.modalService.open(SharedModalComponent, {
      size: 'lg',
      scrollable: true,
      windowClass: 'video-share'
    });

    const url = '/' + this._translatesService.getCurrentLang() + '/video/'
     + this.currentVideo.vimeoId + '/' + this.currentVideo.slug;

    modalRef.componentInstance.url = url;
    modalRef.componentInstance.browserTitle = video.browserTitle;
    modalRef.componentInstance.metaDescription = video.metaDescription;
    modalRef.componentInstance.thumbnail = video.thumbnail;
    modalRef.componentInstance.type = 'video';

    modalRef.result.catch(async() => {
      this.player.play();
    });
  }

  updateUserLevel() {
    if (!this.authService.isAuthenticated() ||
        this.authService.getUserType() === 'club' && this.authService.getUserLevel() !== 3) {
      this.isClub3 = false;
    } else {
      this.isClub3 = true;
    }
  }

  connection() {
    if (!this.isClub3) {
      this.player.pause();
      this.extFormService.clubConnect();
    }
  }

  tip() {
    if (this.player) {
      this.player.pause();
    }
    const modalRef = this.modalService.open(TipModalComponent, {
      size: 'lg',
      scrollable: true,
      windowClass: 'video-modal-tip'
    });
    modalRef.result.catch(async() => {
      this.player.play();
    });
  }
}
