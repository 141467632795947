import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/components/components.module';
import { ContactDialogComponent } from './contact-dialog.component';
import { NgHcaptchaModule } from 'ng-hcaptcha';

@NgModule({
  declarations: [ContactDialogComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule, NgHcaptchaModule],
})
export class ContactDialogModule {}
