import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() title: string;
  @Input() isSmall = false;
  @Input() isLink = false;
  @Input() nextButton: string;
  @Input() preButton: string;
  @Input() centerButton: string;
  @Input() color: string;
  @Input() border = false;
  @Output() cta?: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}
}
