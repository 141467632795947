import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services/api.service';
import { gql } from 'apollo-angular';
import { Article } from './blog.type';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  static subCategoryId: number;

  constructor(private readonly apiService: ApiService) {}

  async queryArticle(
    page: number,
    pageSize: number,
    subCategoryId: number,
  ): Promise<{ totalCount: number; nodes: Article[] }> {
    const input = {
      first: page * pageSize,
      offset: (page - 1) * pageSize,
      subCategoryId: subCategoryId,
    };
    const result = await this.apiService.queryBackOfficeClient<{
      queryArticles: { totalCount: number; nodes: Article[] };
    }>(
      gql`
        query queryArticles($input: ArticleQuery!) {
          queryArticles(input: $input) {
            totalCount
            nodes {
              id
              title
              titleColor
              browserTitle
              description
              metaDescription
              subCategoryId
              slug
              thumbnail
              html
              thumbnailAlt
              imageCover
              coverAlt
              slugId
              createdDate
              updatedDate
              publishDate
            }
          }
        }
      `,
      { input },
    );
    if (result === null) {
      return null;
    }
    return result.queryArticles;
  }

  public async getArticleBySlugId(slugId: string, adminToken?: boolean): Promise<Article> {
    const response = await this.apiService.queryBackOfficeClient(
      gql`
        query getArticleBySlugId {
          getArticleBySlugId(slugId: "${slugId}") {
            id
            title
            titleColor
            browserTitle
            description
            metaDescription
            subCategoryId
            slug
            thumbnail
            html
            thumbnailAlt
            imageCover
            coverAlt
            slugId
            createdDate
            updatedDate
            publishDate
          }
        }
      `, null, false, adminToken);

    if (response) {
      return response.getArticleBySlugId;
    }
    return null;
  }

  public async getRelatedPost(articleId: string): Promise<Article[]> {
    const response = await this.apiService.queryBackOfficeClient(
      gql`
        query getRelatedPost {
          getRelatedPost(id: "${articleId}") {
            id
            title
            titleColor
            browserTitle
            description
            metaDescription
            subCategoryId
            slug
            thumbnail
            html
            thumbnailAlt
            imageCover
            coverAlt
            slugId
            createdDate
            updatedDate
            publishDate
          }
        }
      `,
    );

    if (response) {
      return response.getRelatedPost;
    }
    return null;
  }

  async getImageBlogBySubCategoryId(subCategoryId: number): Promise<BlogImage> {
    const result = await this.apiService.queryBackOfficeClient(
      gql`
        query getImageBlogBySubCategoryId($subCategoryId: Int) {
          getImageBlogBySubCategoryId(subCategoryId: $subCategoryId) {
            id
            url
            subCategoryId
            alt
          }
        }
      `,
      { subCategoryId },
    );
    return result.getImageBlogBySubCategoryId;
  }
}

export interface BlogImage {
  id: string;
  url: string;
  subCategoryId: number;
  alt: string;
}
