import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { map } from 'rxjs/operators';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateService as NGXTranslateService,
} from '@ngx-translate/core';
import { UniversalStorage } from '@shared/storage/universal.storage';
import { Observable, of } from 'rxjs';
import { ILang } from './translates.interface';
import { SEOService } from '@shared/services/seo.service';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';

const LANG_LIST: ILang[] = [
  { code: 'fr', name: 'Francais', culture: 'fr-FR' },
  { code: 'en', name: 'English', culture: 'en-US' },
];
const LANG_DEFAULT: ILang = LANG_LIST[1];
const STORAGE_LANG_NAME: string = 'langCode';

@Injectable()
export class TranslatesService {
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(DOCUMENT) private _document: any,
    @Inject(REQUEST) private _request: any,
    @Inject(NGXTranslateService) private _translate: NGXTranslateService,
    @Inject(REQUEST) private _req: any,
    @Inject(UniversalStorage) private _appStorage: Storage,
    private seoService: SEOService,
    private ccService: NgcCookieConsentService,
  ) {}

  public initLanguage(): Promise<any> {
    this._translate.addLangs(LANG_LIST.map((lang: ILang) => lang.code));
    const language: ILang = this._getLanguage();
    if (language) {
      this._translate.setDefaultLang(language.code);
    } else {
      this._translate.setDefaultLang(LANG_DEFAULT.code);
    }
    return this._setLanguage(language).toPromise();
  }

  private _getLanguage(): ILang {
    let language: ILang = this._getFindLang(this._appStorage.getItem(STORAGE_LANG_NAME));
    if (language) {
      return language;
    }
    if (isPlatformBrowser(this._platformId)) {
      language = this._getFindLang(this._translate.getBrowserLang());
    }
    if (isPlatformServer(this._platformId)) {
      try {
        const reqLangList: string[] = this._request.headers['accept-language']
          .split(';')[0]
          .split(',');
        language = LANG_LIST.find(
          (lang: ILang) =>
            reqLangList.indexOf(lang.code) !== -1 || reqLangList.indexOf(lang.culture) !== -1,
        );
      } catch (err) {
        language = LANG_DEFAULT;
      }
    }
    language = language || LANG_DEFAULT;
    this._appStorage.setItem(STORAGE_LANG_NAME, language.code);
    return language;
  }

  private _getFindLang(code: string): ILang | null {
    return code ? LANG_LIST.find((lang: ILang) => lang.code === code) : null;
  }

  private _setLanguage(lang: ILang): Observable<void> {
    return this._translate.use(lang.code).pipe(
      map(() => {
        this.seoService.updateLocale(lang.culture, this.getCurrentLang());
        this._document.documentElement.lang = lang.code;
        if (!isPlatformServer(this._platformId)) {
          if (this.ccService.isOpen()) {
            this.getText(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow',
             'cookie.deny', 'cookie.link', 'cookie.policy'])
            .subscribe(data => {

              this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
              // Override default messages with the translated ones
              this.ccService.getConfig().content.header = data['cookie.header'];
              this.ccService.getConfig().content.message = data['cookie.message'];
              this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
              this.ccService.getConfig().content.allow = data['cookie.allow'];
              this.ccService.getConfig().content.deny = data['cookie.deny'];
              this.ccService.getConfig().content.link = data['cookie.link'];
              this.ccService.getConfig().content.policy = data['cookie.policy'];
              this.ccService.getConfig().content.href = this.getCurrentLang() + '/pages/cookies';

              this.ccService.destroy(); // remove previous cookie bar (with default messages)
              this.ccService.init(this.ccService.getConfig()); // update config with translated messages
            });
          }
        }
      })
    );
  }

  public changeLang(code: string): Observable<any> {
    const lang: ILang = this._getFindLang(code);
    if (!lang || lang.code === this.getCurrentLang()) {
      return of(null);
    }
    this._appStorage.setItem(STORAGE_LANG_NAME, lang.code);
    return this._setLanguage(lang);
  }

  public getLangList(): Observable<ILang[]> {
    return of(LANG_LIST);
  }

  public getCurrentLang(): string {
    return this._translate.currentLang;
  }

  public getAvailableLangs(): string[] {
    return this._translate.langs;
  }

  public getText(key, interpolateParams?: Object) {
    return this._translate.getTranslation(this.getCurrentLang()).pipe(
      map(translations => {
        return this._translate.getParsedResult(translations.data, key, interpolateParams);
      })
    );
  }
}

export class CommonMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}
