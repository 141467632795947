import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/components/components.module';
import { LoginDialogComponent } from './login-dialog.component';

@NgModule({
  declarations: [LoginDialogComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, ComponentsModule],
})
export class LoginDialogModule {}
