import { Injectable } from "@angular/core";
import { TranslatesService } from "@shared/translates";
import { Apollo } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { environment } from "environments/environment";
import { AuthService } from "./auth.service";
import { JwtHelperService } from '@auth0/angular-jwt';
const jwtHelper = new JwtHelperService();
import { cloneDeep } from 'lodash';

const link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      const error = message as any;
      console.log(error.statusCode);
      console.log(error.error);
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private isInitBack = false;
  private adminToken: boolean;

  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private authService: AuthService,
    private translateService: TranslatesService
  ) {}

  async initBackOfficeGraphQL(): Promise<void> {
    const auth = setContext(async (request, previousContext) => {
      // get the token from session or storage
      const { headers } = previousContext;

      const token = this.adminToken ? `Bearer ${this.authService.adminToken}` : `Bearer ${this.authService.token}`;
      const lang = this.translateService.getCurrentLang();


      if (token !== 'Bearer ') {
        if (jwtHelper.isTokenExpired(token)) {
          await this.authService.refreshToken(token);
        }
      }

      if (!this.authService.isAuthenticated() && !this.adminToken) {
        return {
          headers: {
            lang,
          },
        };
      }
      // Set the Authorization headers
      return {
        headers: {
          Authorization: token,
          lang,
        },
      };
    });
    const http = this.httpLink.create({
      uri: environment.backendUrl + '/graphql',
    });

    this.apollo.createNamed("backEnd", {
      link: auth.concat(link.concat(http)),
      cache: new InMemoryCache(),
    });
    this.isInitBack = true;
  }

  async queryBackOfficeClient<T = any>(
    query: any,
    variables: any = {},
    useCache: boolean = false,
    adminToken?: boolean,
  ): Promise<T> {
    this.adminToken = adminToken;
    if (!this.isInitBack) {
      await this.initBackOfficeGraphQL();
    }
    try {
      const response = await this.apollo
      .use("backEnd")
      .getClient()
      .query({
        query,
        variables,
        fetchPolicy: useCache ? "cache-first" : "network-only",
      });
      this.adminToken = false;
      return cloneDeep(response.data)
    } catch (err) {
      console.log(err);
    }
  }

  async mutateBackOfficeClient<T = any>(
    mutation: any,
    variables: any = {}
  ): Promise<T> {
    if (!this.isInitBack) {
      await this.initBackOfficeGraphQL();
    }
    const t = this.apollo.client;
    try {
      const response = await this.apollo
      .use("backEnd")
      .getClient()
      .mutate({
        mutation,
        variables,
      });
      return cloneDeep(response.data);
    } catch (err) {
      console.log(err);
    }
  }
}
