import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from './button/button.component';
import { CustomInputComponent } from './custom-input/custom-input.component';
import { SharedModalComponent } from './shared-modal/shared-modal.component';
import { TipModalComponent } from './tip-modal/tip-modal.component';
import { VideoCarousselComponent } from './video-caroussel/video-caroussel.component';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
import { VideoSliderComponent } from './video-slider/video-slider.component';
import { SearchComponent } from './search/search.component';
import { PipeModule } from '@shared/pipe/pipe.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TinyReplacerService } from '@shared/services/tinyReplacer.service';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@shared/directive/Directive.module';

@NgModule({
  declarations: [
    VideoCarousselComponent,
    VideoDialogComponent,
    VideoSliderComponent,
    ButtonComponent,
    TipModalComponent,
    CustomInputComponent,
    SharedModalComponent,
    SearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LocalizeRouterModule,
    NgbModule,
    PipeModule,
    FontAwesomeModule,
    DirectivesModule
  ],
  exports: [
    VideoCarousselComponent,
    VideoDialogComponent,
    VideoSliderComponent,
    ButtonComponent,
    TipModalComponent,
    CustomInputComponent,
    SharedModalComponent,
    SearchComponent,
  ],
  providers: [TinyReplacerService],

})
export class ComponentsModule {}
