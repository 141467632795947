import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'decodeUri'})
export class DecodeUriPipe implements PipeTransform  {
  constructor() {}
  transform(value) {
    console.log(value);
    console.log(decodeURIComponent(value));
    return decodeURIComponent(value);
  }
}
