import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatesService } from '@shared/translates';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-tip-modal',
  templateUrl: './tip-modal.component.html',
  styleUrls: ['./tip-modal.component.scss'],
})
export class TipModalComponent implements OnInit {

  tipValue = 0;
  monthly = false;

  transparent = 'transparent';
  darkBlue = 'darkBlue';

  pink = 'pink';
  faTimesCircle = faTimesCircle;
  colorExitModal = 'transparent';

  currentLang;

  constructor(
    public activeModal: NgbActiveModal,
    private _translatesService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.currentLang = this._translatesService.getCurrentLang();
  }

  selectMonth(monthly: boolean) {
    this.monthly = monthly;
  }

  selectTip(tip: number) {
    this.tipValue = tip;
  }

  tip() {
    if (this.monthly) {
      window.location.href = this.currentLang === 'fr' ?
          'https://fr.happygay.tv/cafe' + this.tipValue + 'eurosmensuel?lang=fr-FR' :
          'https://en.happygay.tv/' + this.tipValue + 'eurosmonthlycafe?lang=en-US';
    } else {
      window.location.href = this.currentLang === 'fr' ?
          'https://fr.happygay.tv/cafe' + this.tipValue + 'euros?lang=fr-FR' :
          'https://en.happygay.tv/' + this.tipValue + 'euroscafe?lang=en-US';
    }
  }
}
