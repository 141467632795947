import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SanitizeUrlPipe } from './sanitize-url.pipe';
import { DecodeUriPipe } from './decode-uri.pipe';
import { SubCategoryNamePipe } from './subCategoryName.pipe';

@NgModule({
    imports:        [],
    declarations:   [SafeHtmlPipe, SanitizeUrlPipe, DecodeUriPipe, SubCategoryNamePipe],
    exports:        [SafeHtmlPipe, SanitizeUrlPipe, DecodeUriPipe, SubCategoryNamePipe],
})

export class PipeModule {

  static forRoot() {
     return { ngModule: PipeModule };
  }
}
