import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Video } from '@shared/types';
import { Article } from '../../blog/blog.type';
import { ArticlePlus } from '../../plus/plus.type';
import * as lodash from 'lodash';
import { VideoDialogComponent } from '../video-dialog/video-dialog.component';
import { VideoDialogService } from '../video-dialog/video-dialog.service';
import { VideoSliderService } from './video-slider.service';
import { VideoService } from '../../video/video.service';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { TranslatesService } from '@shared/translates';


@Component({
  selector: 'app-video-slider',
  templateUrl: './video-slider.component.html',
  styleUrls: ['./video-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoSliderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input('title') title: string;
  @Input('videos') videos: Video[];
  @Input('articles') articles: Article[];
  @Input('articlesPlus') articlesPlus: ArticlePlus[];

  @Input('displayTitle') displayTitle = false;

  @Input('z-index') zIndex = 0;
  @Output() onClick = new EventEmitter<Video>();
  @Input() modal = false;
  @Input() billboard;

  @Output() closed = new EventEmitter<boolean>();

  @ViewChild('innerRow') innerRowRef: ElementRef;
  @ViewChildren('tile') tileRef: QueryList<ElementRef>;

  scrollPosition = 0;
  scrollArea = 0;
  offset = 0;
  windowWidth = 0;

  public fillScreen = false;
  multiplier = 2;

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  tilePositions: Array<number>;
  tilePerScreen: number;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private readonly videosliderService: VideoSliderService,
    private route: Router,
    private videoDialogService: VideoDialogService,
    private videoService: VideoService,
    private _translatesService: TranslatesService,
  ) {
    this.route.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };

    this.route.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.route.navigated = false;
        // if you need to scroll back to top, here is the right place
        document.body.scrollTop = 0;
      }
    });
  }

  ngOnInit(): void {
    if (this.videos && this.videos.length > 0) {
      this.videoDialogService.navItem$.subscribe(async item => {
        if (item !== null) {
          this.videos = this.videos.filter((video) => video.id !== item.id);
        }
      });
    }
  }

  ngAfterViewInit() {
    if (this.tileRef && this.tileRef.length > 0) {
      const array = this.tileRef.map((elem) => elem);

      this.offset = array[0].nativeElement.offsetWidth + 10;
      this.windowWidth = window.innerWidth;
      if ((this.offset * 2) > this.windowWidth) {
        this.multiplier = this.multiplier / 2;
      }
      if ((this.offset * 5) < this.windowWidth) {
        this.multiplier = this.multiplier * 2;
      }
      if (this.videos) {
        if (this.innerRowRef.nativeElement.offsetWidth >= this.innerRowRef.nativeElement.scrollWidth) {
          this.fillScreen = true;
          this.innerRowRef.nativeElement.style.overflow = 'hidden';
          this.refresh();
        }
      } else if (this.articles || this.articlesPlus) {
        if (this.innerRowRef.nativeElement.offsetWidth >= this.innerRowRef.nativeElement.scrollWidth) {
          this.fillScreen = true;
          this.innerRowRef.nativeElement.style.overflow = 'hidden';
          this.refresh();
        }
      }
    this.tilePerScreen = window.innerWidth / this.tileRef.first.nativeElement.scrollWidth;
    this.tilePositions = this.tileRef.map((tile) => tile.nativeElement.offsetLeft);
    this.scrollArea = this.innerRowRef.nativeElement.scrollWidth - this.innerRowRef.nativeElement.clientWidth;
    }
  }

  ngOnChanges() {
  }

  refresh() {
    this.cdr.detectChanges();
  }

  onResize(event: any) {
    this.tilePerScreen = window.innerWidth / this.tileRef.first.nativeElement.scrollWidth;
    this.tilePositions = this.tileRef.map((tile) => tile.nativeElement.offsetLeft);
    this.scrollPosition = this.innerRowRef.nativeElement.scrollLeft;
    this.scrollArea = this.innerRowRef.nativeElement.scrollWidth - this.innerRowRef.nativeElement.clientWidth;
    this.refresh();
  }

  public slideRight() {
    this.tilePerScreen = window.innerWidth / this.tileRef.first.nativeElement.scrollWidth;
    this.tilePositions = this.tileRef.map((tile) => tile.nativeElement.offsetLeft);
    this.scrollPosition = this.innerRowRef.nativeElement.scrollLeft;

    const closest = this.tilePositions.filter((position) => position > this.scrollPosition && position > 0);
    this.innerRowRef.nativeElement.scrollLeft = closest[1];
  }

  public slideLeft() {
    this.tilePerScreen = window.innerWidth / this.tileRef.first.nativeElement.scrollWidth;
    this.tilePositions = this.tileRef.map((tile) => tile.nativeElement.offsetLeft);
    this.scrollPosition = this.innerRowRef.nativeElement.scrollLeft;

    const closest = this.tilePositions.filter((position) => position < this.scrollPosition && position > 0);
    this.innerRowRef.nativeElement.scrollLeft = closest[closest.length - Math.round(this.tilePerScreen) + 1];
  }

  public onScroll(event) {
    this.scrollPosition = this.innerRowRef.nativeElement.scrollLeft;
    this.scrollArea = this.innerRowRef.nativeElement.scrollWidth - this.innerRowRef.nativeElement.clientWidth;
  }

  public async goToVideo(video: Video) {

    if (video.club || video.plus) {
      const canAccessVideo = await this.videoService.canAccessVideo(video.id);
      if (!canAccessVideo) {
        this.route.navigate(['/' + this._translatesService.getCurrentLang() + '/classvip']);
        return;
      }
    }
    if (!this.modal) {
      this.route.navigate([
        '/' + this._translatesService.getCurrentLang() + '/video',
        video.vimeoId,
        video.slug,
      ]);
      return;
    }
    if (this.modalService.hasOpenModals()) {
      this.videoDialogService.setVideo(video);
    } else {
      const modalRef = this.modalService.open(VideoDialogComponent, {
        size: 'lg',
        scrollable: true,
      });
      modalRef.componentInstance.video = video;
      modalRef.result.catch(() => {
        this.videosliderService.closeModal();
      });
    }
  }

  public goToArticle(article: Article) {
    const route = '/' + this._translatesService.getCurrentLang() + '/article';
    this.route.navigate([route, article.slugId, article.slug]);
  }
}
