<div class="header-login" ngbAutofocus>
    <div></div>
    <app-button class="timesCircle" aria-label="Close" (click)="activeModal.close('close')" data-dismiss="modal" [isLink]="true" [centerButton]="faTimesCircle" [color]='colorExitModal'></app-button>
    <img class="logo" src="/assets/img/logo.png" alt="footer logo">
</div>
<div class="modal-body">
    <ng-container *ngIf="clubConnect && connected;else elseBlock">
      <div *ngIf="userType !== 'club'" class="col-md-10 mx-auto sign-container">
        <b class="sign-title"><span class="lozange">&#x29EB;</span>{{'data.club.hotlineToClub.txt' | translate }}</b>
        <div class="sign">
          <app-button [title]="'data.club.hotlineToClub.confirm' | translate" [isLink]="true" [color]="color" (cta)="updateTag()"></app-button>
        </div>
      </div>
      <div *ngIf="userType === 'club'" class="col-md-10 mx-auto sign-container">
        <b class="sign-title"><span class="lozange">&#x29EB;</span>{{'data.login.already-in-club' | translate }}</b>
      </div>
    </ng-container>
    <ng-template #elseBlock>
      <div class="col-md-12 sign-container">
        <b class="sign-title"><span class="lozange">&#x29EB;</span>{{ 'data.login.form.title' | translate }}</b>
        <form *ngIf="form" class="row" [formGroup]="form" (ngSubmit)="onSubmit()" #myform="ngForm" (keyup.enter)="onSubmit()">
            <div class="col-md-12 text-center">
                <div class="help-block with-errors" *ngIf="(error || form.hasError('email', 'email')) && isSubmitted">
                    {{ 'data.registration.error.unauthorize' | translate }}
                </div>
            </div>
            <div class="col-md-12">
                <app-custom-input type="email" autocomplete="email" formControlName="email" (input)="valuechange($event)"
                    [placeholder]="'data.login.form.email' | translate">
                </app-custom-input>
                <div class="help-block with-errors" *ngIf="form.hasError('required', 'email') && isSubmitted">
                    {{ 'data.registration.error.required' | translate }}
                </div>
                <div class="help-block with-errors" *ngIf="form.hasError('email', 'email') && isSubmitted">
                    {{ 'data.registration.error.invalid-mail' | translate }}
                </div>
            </div>
            <div class="col-md-12">
                <app-custom-input type="password" autocomplete="password" formControlName="password"
                    [placeholder]="'data.login.form.password' | translate"></app-custom-input>
                <div class="help-block with-errors" *ngIf="form.hasError('required', 'password') && isSubmitted">
                    {{ 'data.registration.error.required' | translate }}
                </div>
            </div>
            <input class="poto" type="text" formControlName="text">
            <div class="col-md-12">
                <a class="forgot-password" (click)='forgotPassword()'>{{ 'data.login.forgot' | translate }}</a>
            </div>
        </form>
        <div class="col-md-10 mx-auto sign">
            <app-button [title]="'data.login.form.submit' | translate" [isLink]="true" [color]="color" (cta)="onSubmit()"></app-button>
        </div>
    </div>
      <div *ngIf="!noRegistration" class="col-md-10 mx-auto sign-container">
        <b class="sign-title"><span class="lozange">&#x29EB;</span>{{'data.registration.go-to.title' | translate }}</b>
        <div class="sign">
          <app-button [title]="'data.registration.go-to.submit' | translate" [isLink]="true" [color]="color" (cta)="openDialog()"></app-button>
        </div>
      </div>
    </ng-template>

</div>
