import { Component, Input, OnInit, AfterViewInit, HostListener, AfterContentInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@shared/services/api.service';
import { AuthService } from '@shared/services/auth.service';
import { StateService } from '@shared/services/state.service';
import { ILang, TranslatesService } from '@shared/translates';
import { Video, Tag } from '@shared/types';
import { LoginDialogComponent } from 'app/auth/components/login-dialog/login-dialog.component';
import { RegistrationDialogComponent } from 'app/auth/components/registration-dialog/registration-dialog.component';
import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { SubCategory } from '@shared/types';
import { CategoryService } from '@shared/services/category.service';

import { UniversalStorage } from '@shared/storage/universal.storage';
import { SearchComponent } from '../../../components/search/search.component';
import { TipModalComponent } from 'app/components/tip-modal/tip-modal.component';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  public langList$: Observable<ILang[]>;
  public currentLang: string;
  isLoggedIn: any;
  public subCategories: SubCategory[];
  @Input() color: 'white' | 'black' = 'white';
  srcHeight: number;
  srcWidth: number;
  icon;
  tagPageListener;
  forcedIconListener;
  blackHeader = false;
  isFirstTime;
  noH1 = false;
  notH1List = ['hotline', 'plus', 'club'];
  hideButton = false;
  hideButtonListener
  @ViewChild('search') search: SearchComponent;

  constructor(
    @Inject(UniversalStorage) private _appStorage: Storage,
    private _translatesService: TranslatesService,
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private apiService: ApiService,
    private categoryService: CategoryService,
    private stateService: StateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isFirstTime = this._appStorage.getItem('read') ? false : true;
    this.currentLang = this._translatesService.getCurrentLang();
    this.tagPageListener = this.stateService._tagPage.subscribe((state) => {
      if (state) {
        this.icon = this.getIcon(state);
      } else {
        this.icon = this.getIcon(null);
      }
    });
    this.forcedIconListener = this.stateService._forcedIcon.subscribe((state) => {
      if (state) {
        if (state === Tag.CLUB) {
          this.icon = '/assets/img/logo-happygaytv-club-class-vip-sigle-negatif.svg';
        }
      }
    });
    this.hideButtonListener = this.stateService.showHeadersButtons.subscribe((state) => {
      this.hideButton = state;
    });
    this.isLoggedIn = this.authService.isAuthenticated();
    this.authService.getSessionChange().subscribe(session => {
      this.isLoggedIn = session;
    });
    const tmpSubCategories = await this.categoryService.subCategoryWithArticle();
    this.subCategories = tmpSubCategories.filter((subCategory) => !subCategory.plus && !subCategory.club);

    const url = window.location.href.split('/');
    if (url.length <= 4 || this.notH1List.includes(url[url.length - 1])) {
      this.noH1 = true;
    }
  }

  ngAfterViewInit() {
    document.body.addEventListener('scroll', () => {
      const verticalOffset = window.pageYOffset
        || document.documentElement.scrollTop
        || document.body.scrollTop
        || 0;
      if (!this.blackHeader && verticalOffset >= 100) {
        this.blackHeader = true;
        document.getElementById('background').classList.add('black-background');
      } else if (this.blackHeader && verticalOffset < 100) {
        document.getElementById('background').classList.remove('black-background');
        this.blackHeader = false;
      }
    });
  }

  ngOnDestroy() {
    this.tagPageListener.unsubscribe();
    this.forcedIconListener.unsubscribe();
  }

  ngAfterContentInit () {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
   getScreenSize(event?) {
     this.srcHeight = window.innerHeight;
     this.srcWidth = window.innerWidth;
   }

  public changeLang(): void {
    this.currentLang = this._translatesService.getCurrentLang();
    const code = this.currentLang === 'fr' ? 'en' : 'fr';

    this._translatesService.changeLang(code).subscribe(() => {
      this.currentLang = code;

      setTimeout(() => {
        const url = window.location.href.split('/');

        url[3] = code;
        window.location.href = url.join(('/'));
      }, 500);
    });
  }

  isActive(lang: string) {
    return this.currentLang === lang ? 'nav-link active' : 'nav-link';
  }

  openDialog(type: string) {
    let modal: NgbModalRef;
    if (type === 'login') {
      modal = this.modalService.open(LoginDialogComponent, {
        windowClass: 'login modal',
        scrollable: true,
      });
      modal.result.then(data => {
        if (data === 'registration') {
          modal = this.modalService.open(RegistrationDialogComponent, {
            windowClass: 'registration modal',
            scrollable: true,
          });
          modal.componentInstance.origin = Tag.CLUB;
        } else if ('success') {
          modal.close();
        }
      });
    }
  }

  logout() {
    this.authService.logOut(true);
  }

  getIcon(tag: Tag) {
    if (tag === Tag.CLUB) {
      const level = this.authService.getUserLevel();
      if (level >= 0) {
        if (level === 0) {
          return this.currentLang === 'fr' ?
          '/assets/img/logo-happygaytv-club-gratuit-sigle-negatif.svg'
          : '/assets/img/logo-happygaytv-club-free-sigle-negatif.svg';
        } else if (level === 3) {
          return '/assets/img/logo-happygaytv-club-class-vip-sigle-negatif.svg';
        }
      }
     return '/assets/img/logo-happygaytv-club-sigle-negatif.svg';
   } else if (tag === Tag.PLUS) {
     return '/assets/img/logo-happygaytv-plus-sigle-negatif.svg';
   }
    return this.currentLang === 'fr'
      ? '/assets/img/logo-happygaytv-fr-reserve.svg'
      : '/assets/img/logo-happygaytv-gb-reserve.svg';
  }

  public slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeiiiioooouuuunc------';

    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  async goToIsFirstTime() {
    let video: Video;
    const response = await this.apiService.queryBackOfficeClient(
      gql`
        query getFirstTimeId {
          getFirstTimeId {
            slug
            vimeoId
          }
        }
      `,
    );
    if (response) {
      video = response.getFirstTimeId;
    } else {
      return;
    }
    this.stateService.setTagPage(null);
    if (video) {
      this.router.navigate([
        '/' + this._translatesService.getCurrentLang() + '/video',
        video.vimeoId,
        video.slug,
      ]);
      this._appStorage.setItem('read', 'true');
    }
  }

  startSearch() {
    this.search.display();
  }

  tip() {
    const modalRef = this.modalService.open(TipModalComponent, {
      size: 'lg',
      scrollable: true,
      windowClass: 'blog-tip'
    });
  }
}
