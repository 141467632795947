import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorsDirective } from '@shared/directive/validators.directive';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '@shared/services/auth.service';
import { TranslatesService } from '@shared/translates';
import { StateService } from '@shared/services/state.service';

@Component({
  selector: 'app-lostpassword-dialog',
  templateUrl: './lost-password-dialog.component.html',
  styleUrls: ['./lost-password-dialog.component.scss'],
})
export class LostPasswordDialogComponent implements OnInit {
  form: FormGroup;
  isSubmitted: boolean;
  color = 'pink';

  faTimesCircle = faTimesCircle;
  colorExitModal = 'transparent';
  send = false;
  afterSubmit = '';

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public authService: AuthService,
    private _translatesService: TranslatesService,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    let lastEmail = '';
    if (this.authService.lastEmailAttempt) {
      lastEmail = this.authService.lastEmailAttempt;
      this.authService.lastEmailAttempt = null;
    }
    this.form = this.formBuilder.group({
      email: [lastEmail, [Validators.required, Validators.email]]
    });
  }

  get f() {
    return this.form.controls;
  }

  async onSubmit() {
    this.isSubmitted = true;

    if (this.form.invalid) {
      return;
    }
    try {
      this.stateService.loading.next(true);
      const result = await this.authService.lostPassword({
        email: this.f.email.value
      });
      this._translatesService.getText('lostpassword.after-submit').subscribe((text) => {
         this.afterSubmit = text.replace('{mail}', this.f.email.value);
      });
      this.send = true;
    } catch (e) {
      return;
    } finally {
      this.stateService.loading.next(false);
    }
  }
}
