import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TranslatesService } from '@shared/translates';
import { Tag } from '../../shared/types';
import { UniversalStorage } from '@shared/storage/universal.storage';

@Injectable()
export class AuthService {
  private _authToken: string;
  private _authState: BehaviorSubject<boolean>;
  private _interruptedUrl: string;
  private _initialData: string[] = ['token', 'interruptedUrl'];
  private sessionSubject: Subject<any> = new Subject();

  public lastEmailAttempt: string;
  retryRefreshToken = 3;
  retryRefreshTokenCounter = 0;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private translateService: TranslatesService,
    @Inject(UniversalStorage) private _appStorage: Storage,
  ) {
    this._authState = new BehaviorSubject(!1);
    this._initialData.forEach(value => {
      this[value] = this._getStoredItems(value);
    });
  }

  public get interruptedUrl(): string {
    return this._interruptedUrl;
  }

  public set interruptedUrl(url: string) {
    this._interruptedUrl = url;
    if (!url) {
      this._appStorage.removeItem('interruptedUrl');
    } else {
      this._saveValueInCookieStorage('interruptedUrl', url);
    }
  }

  public get token(): string {
    return this._authToken ? this._authToken : '';
  }

  public get adminToken(): string {
    return this._appStorage.getItem('adminToken') ? this._appStorage.getItem('adminToken') : '';
  }

  public set token(token: string) {
    this._authToken = token;
    this.changeAuthState = !!token;
  }

  public set changeAuthState(newState: boolean) {
    this._authState.next(newState);
  }

  public isAuthenticated(): boolean {
    // This method is required to implement authentication.
    return !!this.token;
  }

  public getSessionChange(): Observable<any> {
    return this.sessionSubject.asObservable();
  }

  public async logIn(formValue: { email: string; password: string, origin?: string }) {
    const lang = this.translateService.getCurrentLang();

    const headers = {
      lang
    };
    const response = await this._http
      .post<{ userLevel: string, token: string}>(
        environment.backendUrl + '/login',
        {
          email: formValue.email,
          password: formValue.password,
          origin: formValue.origin
        },
        {
          headers
        }
      )
      .toPromise();
    if (response.token && response.userLevel) {
      this._saveValueInCookieStorage('token', response.token);
      this._saveValueInCookieStorage('conv_conn', response.userLevel);
      this.sessionSubject.next(true);
    }
    return response;
  }

  public async refreshToken(token: string) {
    const lang = this.translateService.getCurrentLang();

    const headers = {
      lang
    };
    const response = await this._http
      .post<{ userLevel: string, token: string}>(
        environment.backendUrl + '/refreshToken',
        {
          token: token,
        },
        {
          headers
        }
      )
      .toPromise();
    if (this.retryRefreshTokenCounter >= this.retryRefreshToken) {
      return await this.logOut(true);
    }
    if (response && response.token && response.userLevel) {
      this._saveValueInCookieStorage('token', response.token);
      this._saveValueInCookieStorage('conv_conn', response.userLevel);
      this.sessionSubject.next(true);
      this.retryRefreshTokenCounter = 0;
    } else {
      this.retryRefreshTokenCounter++;
      await this.refreshToken(token);
    }
    return response;
  }

  public async delSub(token: string) {
    const lang = this.translateService.getCurrentLang();

    const headers = {
      lang
    };
    const response = await this._http
      .post<{ token: string}>(
        environment.backendUrl + '/delSub',
        {
          token: token,
        },
        {
          headers
        }
      )
      .toPromise();
    return response;
  }

  public async lostPassword(formValue: { email: string }) {
    const lang = this.translateService.getCurrentLang();

    const headers = {
      lang
    };
    const response = await this._http
      .post<String>(
        environment.backendUrl + '/lostPassword',
        {
          email: formValue.email,
        },
        {
          headers
        }
      )
      .toPromise();
  }

  public async resetPassword(formValue: { password: string, token: string }) {
    const lang = this.translateService.getCurrentLang();

    const headers = {
      lang
    };
    const response = await this._http
      .post<String>(
        environment.backendUrl + '/resetPassword',
        {
          password: formValue.password,
          token: formValue.token,
        },
        {
          headers
        }
      )
      .toPromise();
  }

  public async register(formValue: { email: string, nickname: string, password: string, cgv: boolean, origin: Tag }) {
    const lang = this.translateService.getCurrentLang();
    const headers = {
      lang
    };

    const response = await this._http
      .post<{ token: string}>(
        environment.backendUrl + '/register',
        {
          email: formValue.email,
          nickname: formValue.nickname,
          password: formValue.password,
          cgv: formValue.cgv,
          origin: formValue.origin
        },
        {
          headers
        }
      )
      .toPromise();
    if (response.token) {
      this._saveValueInCookieStorage('token', response.token);
      this.sessionSubject.next(true);
    }
    return response;
  }

  public async confirmRegister(formValue: { token: string }) {
    const lang = this.translateService.getCurrentLang();
    const headers = {
      lang
    };
    const response = await this._http
      .post<{ token: string}>(
        environment.backendUrl + '/confirmRegister',
        {
          token: formValue.token,
        },
        {
          headers
        }
      )
      .toPromise();
    if (response.token) {
      this._saveValueInCookieStorage('token', response.token);
      this.sessionSubject.next(true);
    }
  }

  public async confirmHotline(formValue: { token: string }) {
    const lang = this.translateService.getCurrentLang();
    const headers = {
      lang
    };
    const response = await this._http
      .post<{ token: string}>(
        environment.backendUrl + '/confirmHotline',
        {
          token: formValue.token,
        },
        {
          headers
        }
      )
      .toPromise();
  }

  public async confirmContact(formValue: { token: string }) {
    const lang = this.translateService.getCurrentLang();
    const headers = {
      lang
    };

    const response = await this._http
      .post<{ token: string}>(
        environment.backendUrl + '/confirmContact',
        {
          token: formValue.token,
        },
        {
          headers
        }
      )
      .toPromise();
  }

  public async adminLogin(mail: string, password: string) {
    let response;
    response = await this._http.post<{token: string}>(environment.backendUrl + '/adminLogin',
     {mail: mail, password: password}).toPromise();
    if (response.token) {
      this._appStorage.setItem('adminToken', response.token);
      return true;
    }
    return false;
  }

  public async logOut(goHome?: boolean) {
    this.token = '';
    this._appStorage.removeItem('token');
    this._appStorage.removeItem('conv_conn');
    this.sessionSubject.next(false);
    if (goHome) {
      await this._router.navigate(['/']);
    }
  }

  private _getStoredItems(key: string): any {
    return this._appStorage.getItem(key);
  }

  private _saveValueInCookieStorage(key: string, value: string): void {
    // For saving auth token in Cookie storage.
    this._appStorage.setItem(key, value);
    if (key === 'token') {
      this.token = value;
    }
  }

  public getUserConn(): string {
    return this._appStorage.getItem('conv_conn');
  }

  public getUserLevel(): number {
    const conv_conn = this._appStorage.getItem('conv_conn');
    if (conv_conn) {
      const level = conv_conn.split('_')[1];
      return Number(level);
    }
    return -1;
  }

  public getUserType(): string {
    const conv_conn = this._appStorage.getItem('conv_conn');
    if (conv_conn) {
      const type = conv_conn.split('_')[0];
      return type;
    }
    return null;
  }
}
