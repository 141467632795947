import { Injectable, Inject } from '@angular/core';
import { gql } from 'apollo-angular';
import { ApiService } from '@shared/services/api.service';
import { Tag } from '../../shared/types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private apiService: ApiService
  ) {
  }

  async updateUserTag(tag: Tag) {
    const response = await this.apiService.mutateBackOfficeClient(
      gql`
        mutation {
         updateUserTag(tag: "${tag}")
        }
      `,
    );

    if (response) {
      return response.updateUserTag;
    }
    return null;
  }
}
