import { Pipe, PipeTransform } from '@angular/core';
import { SubCategory } from '@shared/types';

@Pipe({
  name: 'SubCategoryNamePipe'
})
export class SubCategoryNamePipe implements PipeTransform {
  transform(catId: number, catList: Array<SubCategory>) {
    const found = catList.find((c) => c.id === catId);
    return found ? found.name : catId;
  }
}
