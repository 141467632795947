import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorsDirective } from '@shared/directive/validators.directive';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '@shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TranslatesService } from '@shared/translates';
import { StateService } from '@shared/services/state.service';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent implements OnInit {
  submitted = false;
  color = 'pink';
  faTimesCircle = faTimesCircle;
  colorExitModal = 'transparent';
  captchaValue;
  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private _router: Router,
    private _http: HttpClient,
    private translateService: TranslatesService,
    public activeModal: NgbActiveModal,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      subject: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      resume: ['', Validators.required],
      text: ['', Validators.required],
    });
  }

  get f() {
    return this.form.controls;
  }

  getFormControl(control: string): AbstractControl {
    return this.form.get(control);
  }

  async onSubmit() {
    this.submitted = true;
    if (!this.form.controls['name'].valid || !this.form.controls['surname'].valid ||
        !this.form.controls['email'].valid || !this.form.controls['subject'].valid ||
     !this.form.controls['resume'].valid) {
      console.log('invalid form');
      return;
    }
    if (this.f.text.value) {
      console.log('invalid value');
      return;
    }
    this.captchaValue = event;

    const ret = await this.sendMail({name: this.f.name.value, subject: this.f.subject.value, surname: this.f.surname.value,
      email: this.f.email.value, resume: this.f.resume.value});
  }

  public async onVerify(event: any) {
    this.captchaValue = event;

    this.onSubmit();
  }

  public onExpired(event: any) {
    console.log(event);
    alert('Captcha expired, try again');
    this.captchaValue = null;
  }

  public onError(event: any) {
    console.log(event);
    alert('Captcha error, try again');
    this.captchaValue = null;
  }

  public async sendMail(formValue: {name: string, subject: string, surname: string, email: string, resume: string}) {
    const lang = this.translateService.getCurrentLang();
    const headers = {
      lang
    };
    this.stateService.loading.next(true);
    const t = JSON.parse(this.captchaValue.data);
    const token = t.contents.response;
    const response = await this._http
      .post<boolean>(
        environment.backendUrl + '/sendContact',
        {
          name: formValue.name,
          subject: formValue.subject,
          surname: formValue.surname,
          email: formValue.email,
          resume: formValue.resume,
          captcha: token
        },
        {
          headers
        }
      )
      .toPromise();
      this.stateService.loading.next(false);
      if (response === false) {
        console.log('err');
        alert('Captcha error, try again');
        this.captchaValue = null;
        return false;
      }
      return true;
  }
}
