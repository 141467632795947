<div class="header-lost-password">
  <app-button class="timesCircle" aria-label="Close" (click)="activeModal.close('close')" data-dismiss="modal" [isLink]="true" [centerButton]="faTimesCircle" [color]='colorExitModal'></app-button>
  <img class="logo" src="/assets/img/logo.png" alt="footer logo">
</div>
<div class="modal-body">
    <div class="col-md-12 signup">
        <form *ngIf="form && !send" class="row" [formGroup]="form" (ngSubmit)="onSubmit()" #myform="ngForm">
            <div class="col-md-12">
                <div class='desc'>{{'data.lostpassword.desc' | translate }}</div>
                <app-custom-input type="email" autocomplete="email" formControlName="email"
                    [placeholder]="'data.registration.form.email' | translate"></app-custom-input>
                <div class="help-block with-errors" *ngIf="form.hasError('required', 'email') && isSubmitted">
                    {{ 'data.registration.error.required' | translate }}
                </div>
                <div class="help-block with-errors" *ngIf="form.hasError('email', 'email') && isSubmitted">
                    {{ 'data.registration.error.invalid-mail' | translate }}
                </div>
            </div>
            <div class="col-md-12 mx-auto">
              <div class="sign">
                <app-button [title]="'data.lostpassword.submit' | translate" [isLink]="true" [color]="color" (click)='onSubmit()'></app-button>
              </div>
            </div>
        </form>
        <div *ngIf="send" class="after-send">{{afterSubmit}}</div>
        <div *ngIf="send" class="after-send italic orange">{{'data.hotline.contact.label.spam' | translate }}</div>
    </div>
</div>
