<div #searchComponent *ngIf="show" #searchPage class="search-page">
  <div>
    <form [formGroup]="form">
      <app-custom-input #searchInput type="name" formControlName="search"
      [placeholder]="'data.search' | translate"></app-custom-input>
      <app-button #closeSearch class="timesCircle" aria-label="Close" (click)="hide()" [isLink]="true" [centerButton]="faTimesCircle" [color]='colorExitModal'></app-button>
      <div *ngIf="pages && pages.length > 0">
        <a class="faq" [routerLink]="['/pages/faq' | localize]">
          <span>FAQ</span>
          <img width="250px" height="140px" src="/assets/img/question-mark-g61cf83626_1920-min.jpg" alt="faq background img">
        </a>
      </div>
      <app-video-slider *ngIf="videos && videos.length > 0"
        [videos]="videos" title="{{videos.length}} Videos"
        [z-index]=4>
      </app-video-slider>
      <app-video-slider *ngIf="articles && articles.length > 0"
        [articles]="articles" [displayTitle]=true title="{{articles.length}} Articles"
        [z-index]=4>
      </app-video-slider>
      <app-video-slider *ngIf="articlesPlus && articlesPlus.length > 0"
        [articlesPlus]="articlesPlus" [displayTitle]=true title="{{articlesPlus.length}} Articles Plus"
        [z-index]=4>
      </app-video-slider>
    </form>
    <span *ngIf="noResult" class="no-result">
      {{ 'data.no-result' | translate }}
    </span>
  </div>

</div>
