<div class="caroussel">
  <fa-icon *ngIf="trailers.length > 1" class="arrow arrow-left" [icon]="faChevronLeft" (click)="prev()"></fa-icon>
  <fa-icon *ngIf="trailers.length > 1" class="arrow arrow-right" [icon]="faChevronRight" (click)="next()"></fa-icon>
  <div class="frame"*ngFor="let frame of frames; let i = index">
    <iframe [ngClass]="{'hidden': hideFrame(i)}" #ref id="frame-{{i}}" (load)="iframeLoaded(ref, frame, i)" class="iframe iframe-{{i}}" name="{{frame.title}}" preload [src]="frame.vimeoId | sanitizeUrl" allow="autoplay" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    <div [ngClass]="{'hidden': hideFrame(i)}" id="overlay {{i}}" class="overlay" (click)="goToVideo(frame)"></div>
  </div>
</div>

<div id="button">
  <!-- <fa-icon *ngIf="playing" class="pause-play-button" [icon]="faPauseCircle" (click)="pausePlayBack(true)"></fa-icon>
  <fa-icon *ngIf="!playing" class="pause-play-button" [icon]="faPlayCircle" (click)="play(true)"></fa-icon> -->
  <div class="mute">
    <span (click)="mute()" style="font-size: 40px;"><img [src]="volumeIcon" style="width: 56%;margin: 5% 22%;"
      alt="icon-volume"></span>
    </div>
</div>
