import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private route: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error) {
          switch (error.status) {
            case 401: {
              this.Error401Handler();
              break;
            }
            case 404: {
              this.route.navigate(['/404']);
              return;
            }
            default: {
              break;
            }
          }
        }
        const notification = error.error.message || error.statusText;
        // TODO: If Notification (toast) service is present we can show current error notification
        return throwError(notification);
      }),
    );
  }

  Error401Handler() {
    // Auto logout if 401 response returned from api
    this.auth.logOut();
  }
}
