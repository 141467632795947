import { Injectable } from '@angular/core';
import { Video, PlaylistVideo, SubCategory, Category } from '@shared/types';
import { gql } from 'apollo-angular';
import { ApiService } from '@shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(
    private apiService: ApiService
  ) {}

  public async getPlusCategory(): Promise<Category[]> {
    const result = await this.apiService.queryBackOfficeClient<{
      getPlusCategory: Category[];
    }>(
      gql`
        query getPlusCategory {
          getPlusCategory {
            id
            name
            club
            plus
          }
        }
      `,
    );
    return result.getPlusCategory;
  }

  public async getPlusSubCategory(): Promise<SubCategory[]> {
    const result = await this.apiService.queryBackOfficeClient<{
      getPlusSubCategory: SubCategory[];
    }>(
      gql`
        query getPlusSubCategory {
          getPlusSubCategory {
            id
            name
            club
            plus
            slug
          }
        }
      `,
    );
    return result.getPlusSubCategory;
  }

  public async getAllSubCategory(): Promise<SubCategory[]> {
    const result = await this.apiService.queryBackOfficeClient<{
      getAllSubCategory: SubCategory[];
    }>(
      gql`
        query getAllSubCategory {
          getAllSubCategory {
            id
            name
            club
            plus
            slug
          }
        }
      `,
    );
    return result.getAllSubCategory;
  }

  public async subCategoryWithArticle(): Promise<SubCategory[]> {
    const result = await this.apiService.queryBackOfficeClient<{
      subCategoryWithArticle: SubCategory[];
    }>(
      gql`
        query subCategoryWithArticle {
          subCategoryWithArticle {
            id
            name
            club
            plus
            slug
          }
        }
      `,
    );
    return result.subCategoryWithArticle;
  }

  public async getSubcategoryBySlug(slug: string): Promise<SubCategory> {
    const response = await this.apiService.queryBackOfficeClient(
      gql`
        query getSubCategoryBySlug {
          getSubCategoryBySlug(slug: "${slug}") {
            id
            name
            position
            slug
          }
        }
      `,
    );
    if (response) {
      return response.getSubCategoryBySlug;
    }
    return null;
  }

}
