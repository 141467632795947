<div class="modal-header" style="background-color: #0d152a;">
    <h1>{{ currentVideo.title }}</h1>
  <app-button class="timesCircle" aria-label="Close" (click)="activeModal.dismiss('Cross click')" data-dismiss="modal" [isLink]="true" [centerButton]="faTimesCircle" [color]='colorExitModal'></app-button>
</div>
<div id="modal-body" class="modal-body" style="background-color: #0d152a;">
    <div style="width:100%;background-color: #0d152a;" class="mb-3">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe [src]="src | sanitizeUrl" width="300" height="150" id="iframe" allow="autoplay" frameborder="0"
            webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
      </div>
      <div class="ext">
      <div class="ext">
        <div class="flex-elem" (click)='tip()'>
          <img class="mug" src="/assets/img/mug-heart.svg" alt="happygaytv icon">
          {{'data.tips.like' | translate }}
        </div>
        <div *ngIf="!isClub3" class="flex-elem flex-elem-margin" (click)='connection()'>
          <i class="fas fa-bell"></i>
          {{'data.subscribe' | translate}}
        </div>
        <div class="flex-elem flex-elem-margin" (click)='showShareModal(currentVideo)'>
          <i class="fas fa-share"></i>
          {{'data.share' | translate}}
        </div>
      </div>
    </div>
    <div>
        <div class="text" [innerHTML]="currentVideo.description | safeHtml"></div>
    </div>
    <div class="bottom">
      <div class="ext">
        <div class="flex-elem" (click)='tip()'>
          <img class="mug" src="/assets/img/mug-heart.svg" alt="happygaytv icon">
          {{'data.tips.like' | translate }}
        </div>
        <div *ngIf="!isClub3" class="flex-elem flex-elem-margin" (click)='connection()'>
          <i class="fas fa-bell"></i>
          {{'data.subscribe' | translate}}
        </div>
        <div class="flex-elem flex-elem-margin" (click)='showShareModal(currentVideo)'>
          <i class="fas fa-share"></i>
          {{'data.share' | translate}}
        </div>
      </div>
      <app-video-slider #slider *ngIf="bool && categorizedVideo.videos.length > 0" [videos]="categorizedVideo.videos"
        [title]="'data.home.modal.title' | translate" [modal]="true">
      </app-video-slider>
    </div>
</div>
