import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatesService } from '@shared/translates';
import { environment } from 'environments/environment';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faTumblr } from '@fortawesome/free-brands-svg-icons';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-shared-modal',
  templateUrl: './shared-modal.component.html',
  styleUrls: ['./shared-modal.component.scss'],
})
export class SharedModalComponent implements OnInit {
  @Input() url: string;
  @Input() browserTitle: string;
  @Input() metaDescription: string;
  @Input() thumbnail: string;
  @Input() type: 'video' | 'blog';

  faShare = faShare;
  faCircle = faCircle;
  faTwitter = faTwitter;
  faFacebookF = faFacebookF;
  faWhatsapp = faWhatsapp;
  faTumblr = faTumblr;
  faLink = faLink;
  faEnvelope = faEnvelope;
  faCheck = faCheck;
  faTimesCircle = faTimesCircle;
  colorExitModal = 'transparent';

  body;
  subject;

  constructor(
    public activeModal: NgbActiveModal,
    private _translatesService: TranslatesService,
  ) {}

  ngOnInit(): void {
    this.browserTitle = encodeURIComponent(this.browserTitle);
    this.metaDescription = encodeURIComponent(this.metaDescription);
    this.url = 'https://' + environment.domain + this.url;

    this._translatesService.getText('mail-share.' + this.type).subscribe((text) => {
      this.subject = encodeURIComponent(text.title);
      this.body = encodeURIComponent(text.text.replace('{link}', this.url).replace(/<br\s*\/?>/mg, '\n'));
    });
  }

  copyUrl() {
    const check = document.getElementById('check');
    const link = document.getElementById('link');

    link.style.display = 'none';
    check.style.display = 'inline';

    const el = document.createElement('textarea');
    el.value = decodeURIComponent(this.url);
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setTimeout(() => {
      check.style.display = 'none';
      link.style.display = 'inline';
    }, 3000);
  }
}
