import { Injectable } from '@angular/core';
import { Video } from '@shared/types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoDialogService {
  private _navItemSource = new BehaviorSubject<Video>(null);
  constructor() {}

  navItem$ = this._navItemSource.asObservable();
  // service command
  setVideo(video: Video) {
    this._navItemSource.next(video);
  }
}
