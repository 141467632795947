// angular
import { Location } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UniversalStorage } from '@shared/storage/universal.storage';
import { CookieModule } from 'ngx-cookie';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from '@shared/guards/auth.guard';
import { UnAuthGuard } from '@shared/guards/un-auth.guard';
import { ErrorInterceptor } from '@shared/interceptors/error.interceptor';
// interceptors
import { TokenInterceptor } from '@shared/interceptors/token.interceptor';
import { AuthService } from '@shared/services/auth.service';
import { SEOService } from '@shared/services/seo.service';
// shared
import { SharedModule } from '@shared/shared.module';
import { PipeModule } from '@shared/pipe/pipe.module';
import { TranslatesService } from '@shared/translates';
import { ExtFormService } from '@shared/services/extForm.service';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from 'environments/environment';
import { AppComponent } from './app.component';
// components
import { AppRoutes, routes } from './app.routing';

import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { NgHcaptchaModule } from 'ng-hcaptcha';

import { LoginDialogModule } from 'app/auth/components/login-dialog/login-dialog.module';
import { RegistrationDialogModule } from 'app/auth/components/registration-dialog/registration-dialog.module';
import { LostPasswordDialogModule } from 'app/auth/components/lost-password-dialog/lost-password-dialog.module';
import { CardDialogModule } from 'app/auth/components/card-dialog/card-dialog.module';
import { CookieDialogModule } from 'app/components/cookie-dialog/cookie-dialog.module';
import { ComponentsModule } from 'app/components/components.module';
import { PixelModule } from 'ngx-pixel';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain
  },
  palette: {
    popup: {
      background: '#0d152a'
    },
    button: {
      background: '#00a4cb'
    }
  },
  layouts: {
    'my-custom-layout': '{{buttons}}'
  },
  elements: {
  },
  theme: 'classic',
  position: 'bottom-right',
  type: 'opt-in',
};

export function initLanguage(
  translateService: TranslatesService,
): Function {
  return (): Promise<any> => translateService.initLanguage();
}

export function createTranslateLoader(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
) {
  return new ManualParserLoader(
    translate,
    location,
    settings,
    ['fr', 'en']
  );
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    TransferHttpCacheModule,
    HttpClientModule,
    RouterModule,
    AppRoutes,
    NgcCookieConsentModule.forRoot(cookieConfig),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: createTranslateLoader,
        deps: [
          TranslateService,
          Location,
          LocalizeRouterSettings
        ],
      },
      initialNavigation: true,
    }),
    BrowserAnimationsModule,
    CookieModule.forRoot(),
    SharedModule.forRoot(),
    PipeModule.forRoot(),
    FontAwesomeModule,
    LoginDialogModule,
    RegistrationDialogModule,
    LostPasswordDialogModule,
    CookieDialogModule,
    CardDialogModule,
    ComponentsModule,
    NgHcaptchaModule.forRoot({
      siteKey: 'e9ff9a2b-2e8a-4fea-bb5f-c312e341fbf9',
    }),
    PixelModule.forRoot({ enabled: false, pixelId: '272024611519881' })
  ],
  declarations: [AppComponent],
  providers: [
    // CookieService,
    UniversalStorage,
    AuthService,
    // Guards
    AuthGuard,
    UnAuthGuard,
    ExtFormService,
    SEOService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initLanguage,
      multi: true,
      deps: [TranslatesService],
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.backendUrl + '/graphql',
          }),
        };
      },
      deps: [HttpLink],
    },
  ],
})

export class AppModule {
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      console.log('router error go to 404 page');
      console.log(error);
      this.router.navigate(['/404']); // or redirect to default route
    };
  }
}
