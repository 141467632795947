import { Component, ViewChild, OnInit } from '@angular/core';
import { SearchService } from '@shared/services/search.service';
import { TranslatesService } from '@shared/translates';
import { FormGroup, FormBuilder } from '@angular/forms';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { fromEvent, Subscriber, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';

import Fuse from 'fuse.js/dist/fuse';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  form: FormGroup;

  public currentLang: string;
  public show = false;
  public noResult = false;

  public fuse: any;
  public fuseResults: any[];
  public searchRes: any;

  faTimesCircle = faTimesCircle;
  colorExitModal = 'transparent';

  @ViewChild('searchInput') searchInput: any;
  @ViewChild('closeSearch') closeSearch: any;
  @ViewChild('searchComponent') searchComponent: any;
  @ViewChild('searchPage') searchPage: any;

  source: any;
  subscriber: Subscriber<any>;
  routerSub: Subscription;

  videos: any[];
  articles: any[];
  articlesPlus: any[];
  pages: any[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _translatesService: TranslatesService,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      search: [''],
    });
    this.currentLang = this._translatesService.getCurrentLang();
  }

  public async display() {
    this.show = true;
    setTimeout(async () =>  {
      this.routerSub = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.hide();
        }
      });
      this.searchInput.searchInput.nativeElement.focus();

      this.source = fromEvent(this.searchInput.searchInput.nativeElement, 'keyup');

      this.subscriber = this.source.pipe(debounceTime(250)).subscribe(async (c) => {
        this.clear();
        await this.loadSearchItems();
        this.search(this.f.search.value);
        if (this.f.search.value.length === 0) {
          this.noResult = false;
        } else if (this.videos.length === 0 && this.articles.length === 0 &&
            this.articlesPlus.length === 0 && this.pages.length === 0) {
          this.noResult = true;
        } else {
          this.noResult = false;
        }
      });
    }, 500);
  }

  public hide() {
    if (this.show) {
      this.show = false;
      this.form.reset();
      this.subscriber.unsubscribe();
      this.routerSub.unsubscribe();
      this.clear();
    }
  }

  public clear() {
    this.videos = [];
    this.articles = [];
    this.articlesPlus = [];
    this.pages = [];
    this.noResult = false;
  }

  get f() {
    return this.form.controls;
  }

  async loadSearchItems() {
    if (!this.searchRes) {
      this.searchRes = await this.searchService.search();
    }
  }

  search(pattern: string) {
    const options = {
      includeScore: true,
      // shouldSort: true,
      includeMatches: true,
      minMatchCharLength: 2,
      threshold: 0.3,
      ignoreLocation: true,
      keys: [
        'title',
        'browserTitle',
        'txt',
        'description',
        'metaDescription',
        'html'
      ]
    };

    this.fuse = new Fuse(this.searchRes.articles, options);

    this.articles = this.fuse.search('=' + pattern).map(f => f.item);

    this.fuse = new Fuse(this.searchRes.videos, options);
    this.videos = this.fuse.search('=' + pattern).map(f => f.item);

    options.keys = ['title', 'txt'];
    this.fuse = new Fuse(this.searchRes.articlesPlus, options);
    this.articlesPlus = this.fuse.search('=' + pattern).map(f => f.item);

    options.keys = ['subject', this.currentLang === 'fr' ? 'textFr' : 'textEn'];
    this.fuse = new Fuse(this.searchRes.pages, options);
    this.pages = this.fuse.search('=' + pattern).map(f => f.item);
  }
}
