<div class="header-registration">
  <app-button class="timesCircle" aria-label="Close" (click)="activeModal.close('close')" data-dismiss="modal" [isLink]="true" [centerButton]="faTimesCircle" [color]='colorExitModal'></app-button>
  <img class="logo" src="/assets/img/logo.png" alt="footer logo">
</div>
<div class="modal-body">
    <div class="col-md-12 signup">
        <form *ngIf="form" class="row" [formGroup]="form" (ngSubmit)="onSubmit()" #myform="ngForm">
            <div class="col-md-12 text-center">
              <div class="help-block with-errors" *ngIf="error && isSubmitted">
                  {{ 'data.registration.error.unauthorize' | translate }}
              </div>
            </div>
            <div class="col-md-12">
                <app-custom-input type="email" autocomplete="email" formControlName="email"
                    [placeholder]="'data.registration.form.email' | translate"></app-custom-input>
                <div class="help-block with-errors" *ngIf="form.hasError('required', 'email') && isSubmitted">
                    {{ 'data.registration.error.required' | translate }}
                </div>
                <div class="help-block with-errors" *ngIf="form.hasError('email', 'email') && isSubmitted">
                    {{ 'data.registration.error.invalid-mail' | translate }}
                </div>
            </div>
            <div class="col-md-12">
                <app-custom-input type="text" autocomplete="nickname" formControlName="nickname"
                    [placeholder]="'data.registration.form.nickname' | translate"></app-custom-input>
                <div class="help-block with-errors" *ngIf="form.hasError('required', 'nickname') && isSubmitted">
                    {{ 'data.registration.error.required' | translate }}
                </div>
            </div>
            <div class="col-md-12">
                <app-custom-input type="password" autocomplete="new-password" formControlName="password"
                    [placeholder]="'data.registration.form.password' | translate"></app-custom-input>
                <div class="help-block with-errors" *ngIf="form.hasError('required', 'password') && isSubmitted">
                    {{ 'data.registration.error.required' | translate }}
                </div>
                <div class="help-block with-errors" *ngIf="form.hasError('minlength', 'password')">
                    {{ 'data.registration.error.min-length' | translate }}
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <app-custom-input type="password" formControlName="passwordValidation"
                    [placeholder]="'data.registration.form.passwordValidation' | translate"></app-custom-input>
                <div class="help-block with-errors"
                    *ngIf="form.hasError('required', 'passwordValidation') && isSubmitted">
                    {{ 'data.registration.error.required' | translate }}
                </div>
                <div class="help-block with-errors" *ngIf="form.hasError('notSame', 'passwordValidation')">
                    {{ 'data.registration.error.not-same' | translate }}
                </div>
            </div>
            <div class="col-md-12" class='cgv'>
                <input type="checkbox" formControlName="cgv"
                    [placeholder]="'data.registration.form.passwordValidation' | translate">{{'data.registration.form.cgv.title'
                | translate}} <a href='{{currentLang}}/pages/use'>{{'data.registration.form.cgv.here'| translate}}</a>
                <div class="help-block with-errors" *ngIf="f['cgv'].invalid && f['cgv'].errors.required && isSubmitted">
                    {{ 'data.registration.error.required' | translate }}
                </div>
            </div>
            <input class="poto" type="text" formControlName="text">
            <div class="col-md-12 mx-auto">
              <div class="sign">
                <app-button [title]="'data.registration.form.submit' | translate" [isLink]="true" [color]="color" (click)='onSubmit()'></app-button>
              </div>
            </div>
        </form>
    </div>
</div>
<div class="already-subscribed">
  <span>{{ 'data.registration.form.already-subscribed' | translate }}</span>
  <a (click)="backToLogin()">{{ 'data.registration.form.connection' | translate }}</a>
</div>
