<div class="header-login" ngbAutofocus>
    <div></div>
    <app-button class="timesCircle" aria-label="Close" (click)="activeModal.close('close')" data-dismiss="modal" [isLink]="true" [centerButton]="faTimesCircle" [color]='colorExitModal'></app-button>
    <img class="logo" src="/assets/img/logo.png" alt="footer logo">
</div>
<div class="modal-body">
    <div class="col-md-12 sign-container">
      <!-- <b class="sign-title"><span class="lozange">&#x29EB;</span>{{'data.club.hotlineToClub.txt' | translate }}</b> -->
      <b class="sign-title"><span class="lozange">&#x29EB;</span>{{'data.card.stop' | translate }}</b>

      <div class="sign">
        <!-- <app-button [title]="'data.club.hotlineToClub.confirm' | translate" [isLink]="true" [color]="color" (cta)="delCard()"></app-button> -->
        <app-button [title]="'data.card.confirm' | translate" [isLink]="true" [color]="color" (cta)="delSub()"></app-button>
      </div>
    </div>
</div>
