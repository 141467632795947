import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorsDirective } from '@shared/directive/validators.directive';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '@shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TranslatesService } from '@shared/translates';
import { StateService } from '@shared/services/state.service';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { UniversalStorage } from '@shared/storage/universal.storage';

@Component({
  selector: 'app-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss'],
})
export class CookieDialogComponent implements OnInit {
  transparent = 'transparent';
  darkBlue = 'darkBlue';
  faTimesCircle = faTimesCircle;
  colorExitModal = 'transparent';
  public form: FormGroup;

  acceptMarketing = true;

  hideMarketing = true;
  hideEssential = true;

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  currentLang;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private _router: Router,
    private _http: HttpClient,
    private translateService: TranslatesService,
    public activeModal: NgbActiveModal,
    private stateService: StateService,
    private _translatesService: TranslatesService,
    @Inject(UniversalStorage) private _appStorage: Storage,
  ) { }

  ngOnInit() {
    this.acceptMarketing = this._appStorage.getItem('cookieconsent_mark') === 'deny' ? false : true;
    this.currentLang = this._translatesService.getCurrentLang();
  }

  marketing() {
    this.acceptMarketing = !this.acceptMarketing;
  }

  close() {
    this.activeModal.close('close');
  }

  deleteData() {
    this._appStorage.removeItem('conv_session');
    this._appStorage.removeItem('conv_person');
    this._appStorage.removeItem('conv_lastEvents');
    this._appStorage.removeItem('g_id');
    this._appStorage.removeItem('_gid');
    this._appStorage.removeItem('_ga');
    this._appStorage.removeItem('_gat');
    this._appStorage.setItem('cookieconsent_mark', 'deny');
    this._appStorage.setItem('cookieconsent_status', 'deny');
    this._appStorage.removeItem('conv_person');
    this._appStorage.removeItem('conv_session');
  }

  async onSubmit(value: boolean) {
    if (!value) {
      this.deleteData();
      window.location.reload();
      this.activeModal.close('close');
      return;
    } else {
      this.acceptMarketing ? this._appStorage.setItem('cookieconsent_mark', 'allow')
        : this._appStorage.setItem('cookieconsent_mark', 'deny');
      if (!this.acceptMarketing) {
        this.deleteData();
      }
      this._appStorage.setItem('cookieconsent_status', 'allow');
      window.location.reload();
      this.activeModal.close('close');
    }
  }
}
