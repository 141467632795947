<div class="header-contact">
  <app-button class="timesCircle" aria-label="Close" (click)="activeModal.close('close')" data-dismiss="modal" [isLink]="true" [centerButton]="faTimesCircle" [color]='colorExitModal'></app-button>
  <img class="logo" src="/assets/img/logo.png" alt="footer logo">
</div>
<div class="modal-body">
    <div *ngIf="!submitted" class="col-md-12 signup">
        <form *ngIf="form" class="row" [formGroup]="form" (ngSubmit)="onSubmit()" #myform="ngForm">
          <div class="col-md-12">
            <div class='desc'>{{'data.hotline.contact.label.desc' | translate }}</div>
            <app-custom-input type="name" autocomplete="given-name" formControlName="name"
            [placeholder]="'data.hotline.contact.label.firstname' | translate"></app-custom-input>
            <div class="help-block with-errors" *ngIf="form.hasError('required', 'name') && form.controls['name'].dirty">
              {{ 'data.registration.error.required' | translate }}
            </div>
          </div>
          <div class="col-md-12">
            <app-custom-input type="surname" autocomplete="family-name" formControlName="surname"
            [placeholder]="'data.hotline.contact.label.lastname' | translate"></app-custom-input>
            <div class="help-block with-errors" *ngIf="form.hasError('required', 'surname') && form.controls['surname'].dirty">
              {{ 'data.registration.error.required' | translate }}
            </div>
          </div>
          <div class="col-md-12">
            <app-custom-input type="email" autocomplete="email" formControlName="email"
            [placeholder]="'data.registration.form.email' | translate"></app-custom-input>
            <div class="help-block with-errors" *ngIf="form.hasError('required', 'email') && form.controls['email'].dirty">
              {{ 'data.registration.error.required' | translate }}
            </div>
            <div class="help-block with-errors" *ngIf="form.hasError('email', 'email') && form.controls['email'].dirty">
              {{ 'data.registration.error.invalid-mail' | translate }}
            </div>
          </div>
          <div class="col-md-12">
            <app-custom-input type="subject" formControlName="subject"
            [placeholder]="'data.hotline.contact.label.subject' | translate"></app-custom-input>
            <div class="help-block with-errors" *ngIf="form.hasError('required', 'subject') && form.controls['subject'].dirty">
              {{ 'data.registration.error.required' | translate }}
            </div>
          </div>
          <div class="col-md-12">
              <div class="form-group">
                  <textarea class="form-control-input" formControlName="resume" maxlength="5000" [placeholder]="'data.hotline.contact.label.message' | translate"></textarea>
                  <div class="help-block with-errors"
                      *ngIf="getFormControl('resume').invalid && getFormControl('resume').errors.required && form.controls['resume'].dirty">
                      {{ '* required' }}</div>
              </div>
          </div>
          <input class="poto" type="text" formControlName="text">
        </form>
        <div *ngIf="form.controls['name'].valid && form.controls['surname'].valid &&
        form.controls['email'].valid &&
        form.controls['subject'].valid && form.controls['resume'].valid"
         class="col-md-12 bottom-buttons">
          <app-button ngHcaptchaInvisibleButton (verify)="onVerify($event)"
              (expired)="onExpired($event)"
              (error)="onError($event)" [title]="'data.hotline.contact.label.submit' | translate" [isLink]="true"></app-button>
        </div>
    </div>
    <div *ngIf="submitted" class="col-md-12 signup">
      <div class='desc'>{{'data.hotline.contact.label.thanks' | translate }}</div>
      <div class='desc italic orange'>{{'data.hotline.contact.label.spam' | translate }}</div>
    </div>
</div>
