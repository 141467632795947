import { Injectable } from '@angular/core';
import { PixelService } from 'ngx-pixel';
import { BehaviorSubject } from 'rxjs';
declare var Convertful: any;

declare let gtag: Function;
declare let ga: Function;

@Injectable({
  providedIn: 'root',
})

export class InternalCookieService {

  constructor(private pixel: PixelService) {}

  public async addAnalytics() {
    try {
      const head = document.getElementsByTagName('head')[0];

      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=GTM-PZWZT6L';

      head.insertBefore(script, head.firstChild);

      const script2 = document.createElement('script');

      script2.innerHTML = 'window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag(\'consent\', \'default\', { \'ad_storage\': \'denied\', \'analytics_storage\': \'denied\'})';

      head.insertBefore(script2, head.firstChild);

      const script3 = document.createElement('script');

      script3.innerHTML = 'window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag(\'js\', new Date());gtag(\'config\', \'GTM-PZWZT6L\')';
      head.insertBefore(script3, script2);

    } catch (err) {
      console.log(err);
    }
  }

  public async allowGoogleCookies(lang: String) {
    if (gtag) {
      gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
    }
  }

  public async addConvertful() {
    const head = document.getElementsByTagName('head')[0];

    const script = document.createElement('script');
    script.id = 'convertful-api';
    script.src = 'https://app.convertful.com/Convertful.js?owner=7010';
    script.async = true;

    await new Promise(f => setTimeout(f, 2000));
    head.insertBefore(script, head.firstChild);

    script.onload = async () => {
    };

    script.onerror = () => {
    };
  }
}
