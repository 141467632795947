import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe, SecurityContext } from '@angular/core';

@Pipe({ name: 'sanitizeUrl'})
export class SanitizeUrlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}
