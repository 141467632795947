import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Tag } from '@shared/types';

@Injectable({
  providedIn: 'root',
})

export class StateService {

  public tagPage: Tag;
  public _tagPage = new BehaviorSubject<Tag>(undefined);

  public loading = new BehaviorSubject<boolean>(false);

  public upload = new BehaviorSubject<number>(-1);

  public showHeadersButtons = new BehaviorSubject<boolean>(true);

  public forcedIcon: Tag;
  public _forcedIcon = new BehaviorSubject<Tag>(null);

  constructor(
  ) {}

  setTagPage(tag: Tag) {
    this.tagPage = tag;
    this._tagPage.next(this.tagPage);
  }

  setForcedIcon(tag: Tag) {
    this.forcedIcon = tag;
    this._forcedIcon.next(this.forcedIcon);
  }

  getTagPage(): Tag {
    return this.tagPage;
  }
}
