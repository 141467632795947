import { Injectable } from '@angular/core';
import { Seo } from '@shared/types';
import { gql } from 'apollo-angular';
import { ApiService } from '@shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class TargetPageService {
  constructor(
    private apiService: ApiService
  ) {}

  public async getByTargetPage(targetPage: string): Promise<Seo> {
    const result = await this.apiService.queryBackOfficeClient(
      gql`
      {
        getByTargetPage(targetPage: "${targetPage}") {
          id
          browserTitle
          metaTitle
          metaDescription
          targetPage
        }
      }
      `,
    );
    if (result === undefined || result === null) {
      return null;
    }
    return result.getByTargetPage;
  }
}
