import { Component, Injector, Input, ViewChild, ElementRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons';
// import { ControlValueAccessorConnector } from "../control-value-accessor-connector";
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
} from '@angular/forms';

@Component({
  selector: "app-custom-input",
  templateUrl: "./custom-input.component.html",
  styleUrls: ["./custom-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomInputComponent,
      multi: true,
    },
  ],
})
export class CustomInputComponent implements ControlValueAccessor {
  @Input() type: string;
  @Input() autocomplete: string;
  @Input() placeholder: string;
  @Input() text: string;

  faEye = faEye;
  faEyeSlash = faEyeSlash;
  displayPass = false;
  orgType;

  @ViewChild('searchInput') searchInput: ElementRef;
  @Input()
  formControl: FormControl;
  @Input()
  formControlName: string;
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;

  touched = false;

  disabled = false;

  value;

  onChange = (_: any) => {};

  onTouched = () => {};

  constructor(private injector: Injector) {}

  get control() {
    return (
      this.formControl ||
      this.controlContainer.control.get(this.formControlName)
    );
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  togglePass() {
    this.displayPass = !this.displayPass;
    this.orgType = 'password';
    if (this.displayPass) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }
}
