import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "@shared/services/auth.service";
import { UserService } from '@shared/services/user.service';
import { RegistrationDialogService } from "../registration-dialog/registration-dialog.service";
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { Tag } from '@shared/types';
import { TranslatesService } from '@shared/translates';
import { StateService } from '@shared/services/state.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-login-dialog",
  templateUrl: "./login-dialog.component.html",
  styleUrls: ["./login-dialog.component.scss"],
})
export class LoginDialogComponent implements OnInit {
  form: FormGroup;
  isSubmitted: boolean;
  error = false;
  color = 'pink';
  faTimesCircle = faTimesCircle;
  colorExitModal = 'transparent';
  connected;
  origin: Tag;
  noRegistration = false;
  clubConnect = false;
  userType;

  constructor(
    private formBuilder: FormBuilder,
    private registrationDialogService: RegistrationDialogService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private authService: AuthService,
    private _translatesService: TranslatesService,
    private userService: UserService,
    private stateService: StateService,
    public route: Router,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
      text: [''],
    });
    this.connected = this.authService.isAuthenticated();
    this.userType = this.authService.getUserType();
  }

  get f() {
    return this.form.controls;
  }

  valuechange(newValue) {
    if (this.error) {
      this.error = false;
    }
  }

  async onSubmit() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    if (this.f.text.value) {
      return;
    }
    try {
      this.stateService.loading.next(true);
      const result = await this.authService.logIn({
        email: this.f.email.value,
        password: this.f.password.value,
        origin: this.origin
      });
    } catch (e) {
      this.error = true;
      return;
    } finally {
      this.stateService.loading.next(false);
    }
    this.activeModal.close("success");
  }

  openDialog() {
    this.activeModal.close("registration");
  }

  async updateTag() {
    console.log('updateTag');
    this.stateService.loading.next(true);

    await this.userService.updateUserTag(Tag.CLUB);
    await this.authService.refreshToken('Bearer ' + this.authService.token);
    this.stateService.loading.next(false);

    this.activeModal.close('success');
    this.route.navigate(['/' + this._translatesService.getCurrentLang() + '/thanksclassvip']);
  }

  close() {
    this.activeModal.close("close");
  }

  forgotPassword() {
    if (this.f.email.valid) {
      this.authService.lastEmailAttempt = this.f.email.value;
    }
    this.activeModal.close('lost-password');
  }
}
