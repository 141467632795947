import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services/api.service';
import { AuthService } from '@shared/services/auth.service';
import { UserInput } from '@shared/types';
import { gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class RegistrationDialogService {
  constructor(private apiService: ApiService, private authService: AuthService) {}

  public async createUser(user: UserInput): Promise<Boolean> {
    const result = await this.apiService.mutateBackOfficeClient<{
      createOrUpdateUser: boolean;
    }>(
      gql`
        mutation createOrUpdateUser {
          createOrUpdateUser(user: {
            email: "${user.email}",
            nickname: "${user.nickname}",
            password: "${user.password}",
          })
        }
      `,
    );
    if (result) {
      this.authService.logIn({ email: user.email, password: user.password });
      return result.createOrUpdateUser;
    } else {
      console.log('unable to create user');
    }
    return null;
  }
}
