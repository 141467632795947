import { Directive } from "@angular/core";
import { AbstractControl, ValidatorFn } from "@angular/forms";

@Directive({
  selector: "[appValidators]",
})
export class ValidatorsDirective {
  constructor() {}

  static checkSamePassword(pass: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return pass !== control.value ? { notSame: true } : null;
    };
  }
}
