import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { ApiService } from '@shared/services/api.service';


@Injectable({
  providedIn: 'root',
})

export class SearchService {

  constructor (
    private apiService: ApiService
  ) {}

  public async search(): Promise<any> {
    const result = await this.apiService.queryBackOfficeClient<{
      getSearch: any;
    }>(
      gql`
      query getSearch {
        getSearch(request: "dede"){
          videos {
            id
            title
            browserTitle
            description
            metaDescription
            thumbnail
            vimeoId
            slug
          }
          articles {
            title
            browserTitle
            description
            metaDescription
            thumbnail
            slugId
            slug
          }
          articlesPlus {
            id
            title
            txt
            thumbnail
          }
          pages {
            subject
            textEn
            textFr
          }
        }
      }
      `
    );
    return result ? result.getSearch : null;
  }

}
