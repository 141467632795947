<div class="modal-header" ngbAutofocus>
  <div>
    <img class="mug" src="/assets/img/mug-heart.svg" alt="happygaytv icon">
  </div>
  <span class="header-txt">{{'data.tips.header' | translate }}</span>
  <app-button class="timesCircle" aria-label="Close" (click)="activeModal.dismiss('Cross click')" data-dismiss="modal" [isLink]="true" [centerButton]="faTimesCircle" [color]='colorExitModal'></app-button>
</div>
<div class="modal-cont" style="border: none;">
  <div class="modal-body" style="text-align: center;">
    <span class="txt">{{'data.tips.txt' | translate }}</span>
    <div class="monthly">
      <app-button [title]="'data.tips.give-once' | translate" [isLink]="true" [border]=true [color]="!monthly ? null : transparent" (cta)="selectMonth(false)"></app-button>
      <app-button [title]="'data.tips.give-month' | translate" [isLink]="true" [border]=true [color]="monthly ? null : transparent" (cta)="selectMonth(true)"></app-button>
    </div>
    <div class="price">
      <app-button [title]="'2€'" [isLink]="true" [border]=true [color]="tipValue === 2 ? null : transparent" (cta)="selectTip(2)"></app-button>
      <app-button [title]="'5€'" [isLink]="true" [border]=true [color]="tipValue === 5 ? null : transparent" (cta)="selectTip(5)"></app-button>
      <app-button [title]="'10€'" [isLink]="true" [border]=true [color]="tipValue === 10 ? null : transparent" (cta)="selectTip(10)"></app-button>
      <app-button [title]="'50€'" [isLink]="true" [border]=true [color]="tipValue === 50 ? null : transparent" (cta)="selectTip(50)"></app-button>
      <app-button [title]="'100€'" [isLink]="true" [border]=true [color]="tipValue === 100 ? null : transparent" (cta)="selectTip(100)"></app-button>

    </div>
    <div class="give">
      <app-button *ngIf="tipValue" [title]="'data.tips.give-now' | translate" [isLink]="true" [color]="pink" (cta)="tip()"></app-button>
    </div>
    <span class="thanks">{{'data.tips.thanks' | translate }}</span>
  </div>
</div>
