import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { gql } from 'apollo-angular';

@Injectable()
export class SEOService {
  currentLang;

  constructor(
    private title: Title,
    private meta: Meta) {
    this.meta.updateTag({property: 'og:site_name', content: 'HappyGayTv'});
    this.meta.updateTag({property: 'og:type', content: 'website'});
  }

  updateTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
    this.meta.updateTag({ property: 'og:description', content: desc });
  }

  updateOgImage(url: string) {
    this.meta.updateTag({ property: 'og:image', content: url });
  }

  updateLocale(url: string, currentLang: string) {
    this.currentLang = currentLang;
    this.meta.updateTag({ property: 'og:locale', content: url });
  }
}
