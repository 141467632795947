import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/components/components.module';
import { CookieDialogComponent } from './cookie-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [CookieDialogComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, ComponentsModule, FontAwesomeModule],
})
export class CookieDialogModule {}
